/*
*  Material Design UIKit
*  author: JEANVERA
*  Version: MDUIkit 1.5.1
*
* */

/* Material Utilities */
:root {
  --mdc-theme-primary: #6200EE;
  --mdc-theme-on-primary: #fff;
  --mdc-theme-secondary: #FF4081;
  --mdc-theme-on-secondary: #fff;
  --mdc-theme-surface: #fff;
  --mdc-theme-on-surface: 0,0,0;
  --mdc-theme-background: #f6f7f9;
  --mdc-theme-app-bar: #6200EE;
  --mdc-theme-on-app-bar: #fff;
}

html {
  background-color: var(--mdc-theme-background);
}

/* html, body {
  margin-top: 0; 
  height: 100%;
  min-height: 100%; 
  } */
  input,
  textarea,
  button,
  select,
  a,
  span,
  div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  @font-face {
    font-family: 'Google Sans';
    src: url('../fonts/GoogleSans-Regular.ttf');
  }

/*  .uk-offcanvas-bar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: red;
  }*/

  .uk-offcanvas-bar::-webkit-scrollbar {
    width: 8px;
  }
  .uk-offcanvas-bar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(var(--mdc-theme-on-surface),0.2);
  }

  #admin-main {
    padding: 24px 0px;
  }

  /* Material System */
  body {
    /* min-height: 100%; */
    font: 400 14px / 1.42857143 "Google Sans", sans-serif;
    /* padding-top: 48px; */
  /* -webkit-box-sizing: border-box;
  box-sizing: border-box; */
}

a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Google Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
}
.text-on-app-bar{
  color: var(--mdc-theme-on-app-bar) !important;
}
.text-on-primary{
  color: var(--mdc-theme-on-primary) !important;
}
.text-on-secondary{
  color: var(--mdc-theme-on-secondary) !important;
}
.uk-card-title {
  font-size: 1.25rem;
  line-height: 1.4;
}

.uk-section-primary {
  background: var(--mdc-theme-primary);
}

#admin-header,
#admin-main,
.md-drawer_open>div>ul {
  will-change: margin;
  -webkit-transition: margin 280ms;
  transition: margin 280ms;
}

#sidebar_main .sidebar_main_header .sidebar_logo a {
  display: inline-block;
  margin-left: 20px;
}
.uk-dropdown-nav .uk-nav-divider{
  border-top: 1px solid rgba(var(--mdc-theme-on-surface), .12);
}
/* Material Drawer */
@media only screen and (max-width: 1219px) {
  #md-drawer .uk-offcanvas-bar {
    -webkit-box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
  }
}

#md-drawer.fullscreen {
  display: block;
}

#md-drawer.fullscreen .uk-offcanvas-bar {
  left: 0px;
}

.md-bottom-navigation {
  height: 56px;
  background-color: var(--mdc-theme-surface);
  width: 100%;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 8;
}

.md-bottom-navigation-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-left: 0;
}

@media only screen and (min-width: 1220px) {

  .md-drawer_open #admin-header,
  .md-drawer_open #admin-main,
  .md-drawer_open>div>ul {
    margin-left: 256px;
  }

  #md-drawer {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  #md-drawer .uk-offcanvas-bar {
    border-right: 1px solid rgba(0, 0, 0, .12);
  }

  .md-drawer_open .md-bottom-navigation-fixed {
    width: calc(100% - 256px);
  }
}

#md-drawer .uk-offcanvas-bar {
  width: 256px;
  background: var(--mdc-theme-surface);
  padding: 0px;
  -webkit-transition: all 280ms cubic-bezier(.4, 0, .2, 1);
  transition: all 280ms cubic-bezier(.4, 0, .2, 1);
}

#md-drawer .uk-offcanvas-overlay::before {
  background: rgba(0, 0, 0, .32);
}

.uk-offcanvas-bar-animation {
  -webkit-transition: all 280ms cubic-bezier(.4, 0, .2, 1);
  transition: all 280ms cubic-bezier(.4, 0, .2, 1);
}

#md-drawer .uk-offcanvas-bar .md-drawer_header {
  height: 89px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 8px;
  background-repeat: no-repeat;
  background-position: 0 0;
  position: relative;

}

#md-drawer .uk-offcanvas-bar .md-drawer_header_title {
  text-align: left;
  font-size: 1.25rem;
  -webkit-font-smoothing: antialiased;
  letter-spacing: .0125em;
  color: #333;
}

#md-drawer .uk-offcanvas-bar .md-drawer_header_subtitle {
  text-align: left;
  color: rgba(0, 0, 0, .6);
}

#md-drawer .uk-offcanvas-bar .uk-nav a {
  font: 500 14px / 25px "Google Sans", sans-serif;
  color: #212121;
  display: block;
  overflow: hidden;
  padding: 0px;
  position: relative;
  -webkit-transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  -moz-transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  -o-transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
}
#md-drawer .uk-offcanvas-bar .uk-nav a > div {
  padding: 8px;
  border-radius: 5px;
}
#md-drawer .uk-offcanvas-bar .uk-nav .uk-parent .uk-nav-sub>li {
  padding: 0px 5px 5px 8px;
}

#md-drawer .uk-offcanvas-bar .uk-nav .uk-parent .uk-nav-sub>li a {
  padding: 8px 10px 8px 32px;
  display: block;
  font: 400 13px / 18px "Google Sans", sans-serif;
  color: rgb(var(--mdc-theme-on-surface));
  border-radius: 5px;
  -webkit-transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  -moz-transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  -o-transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
}
/*.uk-offcanvas-bar .uk-nav .uk-open a span{
   color: var(--mdc-theme-secondary);
}
.uk-offcanvas-bar .uk-nav .uk-open a span i{
   color: var(--mdc-theme-secondary);
   }*/
   #md-drawer .uk-offcanvas-bar .uk-nav .uk-parent .uk-nav-sub>li.uk-active a {
    color: var(--mdc-theme-secondary);
  }

  #md-drawer .uk-offcanvas-bar .uk-nav a:hover {
    /*background-color: rgb(245, 245, 245);*/
    border-radius: 5px;
    text-decoration: none;
  }

  #md-drawer .uk-offcanvas-bar .uk-nav li:not(.uk-nav-divider) {
    text-transform: none;
    text-align: left;
    padding: 0px 8px 8px 8px;
    margin: 0;
  }

  #md-drawer .uk-offcanvas-bar .uk-nav li.uk-nav-header {
    margin-left: 8px;
    text-align: left;
    font-weight: 400;
    letter-spacing: .0178571429em;
    -webkit-font-smoothing: antialiased;
    font-size: .875rem;
    color: rgba(0, 0, 0, .6);
  }

  #md-drawer .uk-offcanvas-bar .uk-nav li.uk-nav-divider {
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, .12);
    margin-bottom: 10px;
    margin-top: 0px;
  }

  #md-drawer .uk-offcanvas-bar .uk-nav .uk-parent>a::after {
    position: absolute;
    top: 10px;
    right: 7px;
    width: 18px;
    height: 24px;
    content: '\e313';
    font-family: "Material Icons";
    font-size: 18px;
    display: block;
    color: rgba(var(--mdc-theme-on-surface), .54);
    -webkit-transition: -webkit-transform 280ms;
    transition: -webkit-transform 280ms;
    transition: transform 280ms;
    transition: transform 280ms, -webkit-transform 280ms;
  }

  #md-drawer .uk-offcanvas-bar .uk-nav .uk-parent.uk-open>a::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  #md-drawer .uk-offcanvas-bar .uk-nav li a .menu_icon {
    width: 48px;
    text-align: left;
    /* display: inline-block; */
    padding-right: 20px;
    color: rgba(var(--mdc-theme-on-surface), .54);
  }

  #md-drawer .uk-offcanvas-bar .uk-nav li a span {
    text-align: left;
    /* display: inline-block; */
    padding-right: 8px;
    color: rgba(var(--mdc-theme-on-surface), .87);
  }

  #md-drawer .uk-offcanvas-bar .uk-nav li a .menu_icon .material-icons {
    font-size: 24px;
    vertical-align: top;
  }

  #md-drawer .uk-offcanvas-bar .uk-nav li.uk-active:not(.uk-open)>a:after {
    display: block;
    content: " ";
    position: absolute;
    background: var(--mdc-theme-secondary);
    opacity: 0.2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
  }

  #md-drawer .uk-offcanvas-bar .uk-nav li.uk-active>a .menu_icon .material-icons {
    color: var(--mdc-theme-secondary);
  }

  #md-drawer .uk-offcanvas-bar .uk-nav li.uk-active>a .menu_title {
    color: var(--mdc-theme-secondary);
  }

  #md-drawer .uk-offcanvas-bar .uk-nav li a:active {
    /*background-color: #E1E1E1;*/
  }

  /* Material Card */
  .md-card {
    background: #fff;
    position: relative;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border: none;
    border-radius: 6px;
  }

  .md-card .md-card-content {
    padding: 16px;
  }


  /* Material Dropdown */
  .uk-navbar-dropdown,
  .uk-dropdown {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
    -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
    border-radius: 4px;
    width: auto;
    min-width: 112px;
    /* 112 */
    box-sizing: border-box;
    padding: 8px 0;
    background-color: var(--mdc-theme-surface);
  }

  .uk-nav.uk-dropdown-nav {
    min-width: 112px;
  }

  .uk-navbar-dropdown>ul>li:not(.uk-nav-divider):not(.uk-nav-header),
  .uk-dropdown>ul>li:not(.uk-nav-divider):not(.uk-nav-header) {
    height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .uk-dropdown-nav .uk-nav-header {
    color: #999;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 12px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
            supported by Chrome, Edge, Opera and Firefox */
          }

          .uk-nav-header:not(:first-child) {
            margin-top: 5px;

          }

          .uk-navbar-dropdown.md-dropdown-dense>ul>li:not(.uk-nav-divider):not(.uk-nav-header),
          .uk-dropdown.md-dropdown-dense>ul>li:not(.uk-nav-divider):not(.uk-nav-header) {
            height: 32px;
          }

          .uk-dropdown>.uk-nav>li>a,
          .uk-dropdown-nav.uk-nav>li>a {
            color: rgba(var(--mdc-theme-on-surface), 0.87);
            padding: 5px 15px;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
            supported by Chrome, Edge, Opera and Firefox */
          }

          .uk-dropdown>.uk-nav>li>a>i.material-icons,
          .uk-dropdown-nav.uk-nav>li>a>i.material-icons {
            color: rgba(var(--mdc-theme-on-surface), 0.87);
            font-size: 18px;
            vertical-align: -4px;
            margin-right: 8px;
          }

          .uk-nav>li.disabled>a {
            color: rgba(var(--mdc-theme-on-surface), 0.40);
          }

          .uk-nav>li.disabled>a>i.material-icons {
            color: rgba(var(--mdc-theme-on-surface), 0.40);
          }

          .uk-dropdown .uk-nav>li:hover:not(.uk-nav-header):not(.disabled) {
            background-color: rgba(0, 0, 0, 0.08);
          }

          .uk-nav>li.disabled>a:hover {
            cursor: default;
          }

          [class*=uk-dropdown-bottom] {
            margin-top: 5px;
          }

          .uk-dropdown-grid>:not(.uk-first-column)::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            border-left: 1px solid rgba(var(--mdc-theme-on-surface), .12);
          }

          .uk-dropdown-grid>div>.uk-panel {
            font-size: .875rem;
            padding: 5px 15px;

          }

          /* Material App Bar */
          .uk-navbar-toggle,.uk-close{
            color: rgba(var(--mdc-theme-on-surface),0.7)
          }
          .uk-navbar-toggle:hover,.uk-close:hover{
            color: rgba(var(--mdc-theme-on-surface),0.9)
          }

          .uk-navbar-item,
          .uk-navbar-nav>li>a,
          .uk-navbar-toggle {
            padding: 0 12px;
          }

          .uk-navbar.uk-navbar-container {
            background-color: var(--mdc-theme-app-bar);
            height: 64px;
            padding: 0;
            /* padding: 0 12px; */
          }

          .uk-navbar.uk-navbar-container.md-top-app-bar-dense {
            height: 48px;
          }

          .uk-navbar-item,
          .uk-navbar-nav>li>a,
          .uk-navbar-toggle {
            min-height: 48;
          }

/* .md-top-app-bar-container {
  position: fixed;
  z-index: 1104;
  width: 100%;
  transition: top 0.3s;
  } */
  .uk-breadcrumb {
    margin-bottom: 0;
    padding: 10px;
    background-color: var(--mdc-theme-surface);
  }

  .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before {
    content: "/";
  }

  .uk-breadcrumb>*>* {
    color: var(--mdc-theme-secondary);
  }

  .md-top-app-bar__title {
    font-family: Google Sans, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: .0125em;
    text-decoration: inherit;
    text-transform: inherit;
    padding-left: 8px;
    padding-right: 0;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1;
  }

  .uk-sticky {
    -webkit-transition: -webkit-box-shadow .5s linear;
    transition: -webkit-box-shadow .5s linear;
    transition: box-shadow .5s linear;
    transition: box-shadow .5s linear, -webkit-box-shadow .5s linear;
  }

  .uk-sticky.uk-active {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
  }

  /* Material Bottom Navigation */


  .md-bottom-navigation__list {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .md-bottom-navigation__list-item {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12px;
    min-width: 60px;
    max-width: 168px;
    box-sizing: border-box;
    color: rgba(var(--mdc-theme-on-surface), 0.54);
    user-select: none;
    cursor: pointer;
  }

  .md-bottom-navigation__list-item__icon {
    padding-top: 8px;
    pointer-events: none;
    transition-property: padding-top, color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 100ms;
  }

  .md-bottom-navigation__list-item__icon path {
    fill: rgba(var(--mdc-theme-on-surface), 0.54);
  }

  .md-bottom-navigation__list-item__text {
    margin-top: auto;
    padding-bottom: 10px;
    font-size: 0.75rem;
    pointer-events: none;
    transition-property: font-size, color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 100ms;
  }

  .md-bottom-navigation__list-item--activated .md-bottom-navigation__list-item__icon {
    padding-top: 6px;
  }

  .md-bottom-navigation__list-item--activated .md-bottom-navigation__list-item__text {
    font-size: 0.875rem;
  }

  .md-bottom-navigation__list-item--activated .md-bottom-navigation__list-item__icon,
  .md-bottom-navigation__list-item--activated .md-bottom-navigation__list-item__text {
    color: var(--mdc-theme-secondary);
  }

  .md-bottom-navigation__list-item--activated .md-bottom-navigation__list-item__icon path,
  .md-bottom-navigation__list-item--activated .md-bottom-navigation__list-item__text path {
    fill: var(--mdc-theme-primary);
  }

  .md-bottom-navigation--shifting .md-bottom-navigation__list-item {
    min-width: 56px;
    max-width: 96px;
    transition-property: min-width, max-width;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 100ms;
  }

  .md-bottom-navigation--shifting .md-bottom-navigation__list-item .md-bottom-navigation__list-item__icon {
    padding-top: 16px;
    transition-property: padding-top;
  }

  .md-bottom-navigation--shifting .md-bottom-navigation__list-item .md-bottom-navigation__list-item__text {
    /* position: absolute; */
    line-height: 10px;
    bottom: 0;
    opacity: 0;
    transition-property: opacity, font-size;
  }

  .md-bottom-navigation--shifting .md-bottom-navigation__list-item--activated {
    min-width: 96px;
    max-width: 168px;
  }

  .md-bottom-navigation--shifting .md-bottom-navigation__list-item--activated .md-bottom-navigation__list-item__icon {
    padding-top: 8px;
    transition-property: padding-top;
  }

  .md-bottom-navigation--shifting .md-bottom-navigation__list-item--activated .md-bottom-navigation__list-item__text {
    white-space: nowrap;
    opacity: 1;
  }
  /* MD list */
  .uk-list {
    margin: 10px 0px;
  }
  .uk-list > li {
    padding: 16px;
    margin: 0px !important;
    cursor: pointer;
  }
  .uk-list > li:not(.ripple-surface):hover {
    background-color: rgba(var(--mdc-theme-on-surface),0.04);
  }
  .uk-list-divider > :nth-child(n + 2) {
    margin-top: 0px !important;
    padding-top: 16px;
  }
  .uk-list .md-radio {
    margin: 0px;
  }
  .uk-list .md-radio>label {
    padding: 0 11px;
  }
  .uk-list .md-checkbox {
    margin: 0 3px 0 0;

  }
  .uk-list-divider>:nth-child(n+2) {
    border-top: 1px solid rgba(var(--mdc-theme-on-surface), .12);
  }
  /* Material Switcher */
  .md-switch>input[type="checkbox"] {
    display: none;
  }

  .switch-label {
    position: relative;
    display: inline-block;
    min-width: 112px;
    cursor: pointer;
    font-weight: 500;
    text-align: left;
    padding: 0 0 0 44px;
  }

  .switch-label:before,
  .switch-label:after {
    content: "";
    position: absolute;
    margin: 0;
    outline: 0;
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .switch-label:before {
    left: 1px;
    width: 34px;
    height: 14px;
    background-color: #9E9E9E;
    border-radius: 8px;
  }

  .switch-label:after {
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #FAFAFA;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
  }

  .switch-label .toggle--on {
    display: none;
  }

  .switch-label .toggle--off {
    display: inline-block;
  }

  .md-switch>input[type="checkbox"]:checked+.switch-label:before {
    background: var(--mdc-theme-secondary);
    opacity: 0.2;
  }

  .md-switch>input[type="checkbox"]:checked+.switch-label:after {
    background-color: var(--mdc-theme-secondary);
    -ms-transform: translate(80%, -50%);
    -webkit-transform: translate(80%, -50%);
    transform: translate(80%, -50%);
  }

  .md-switch>input[type="checkbox"]:checked+.switch-label .toggle--on {
    display: inline-block;
  }

  .md-switch>input[type="checkbox"]:checked+.switch-label .toggle--off {
    display: none;
  }

  /* Material Checkbox */
  .md-checkbox {
    position: relative;
    margin: 1em 0;
    text-align: left;
  }

  .md-checkbox.md-checkbox-inline {
    display: inline-block;
  }

  .md-checkbox>label {
    cursor: pointer;
    display: inline;
    line-height: 1.40em;
    vertical-align: top;
    clear: both;
    padding-left: 1px;
  }

  .md-checkbox>label:not(:empty) {
    padding-left: .75em;
  }

  .md-checkbox>label:before,
  .md-checkbox>label:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
  }

  .md-checkbox>label:before {
    width: 1.10em;
    height: 1.10em;
    background: rgb(var(--mdc-theme-surface));
    border: 2px solid rgba(var(--mdc-theme-on-surface), 0.54);
    border-radius: 0.125em;
    cursor: pointer;
    transition: background .3s;
  }

  .md-checkbox>input[type="checkbox"] {
    outline: 0;
    visibility: hidden;
    width: 1.25em;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;
  }

  .md-checkbox>input[type="checkbox"]:checked+label:before {
    background: var(--mdc-theme-secondary);
    border: none;
    width: 1.35em;
    height: 1.35em;
  }

  .md-checkbox>input[type="checkbox"]:checked+label:after {
    transform: translate(0.25em, 0.3365384615em) rotate(-45deg);
    width: 0.75em;
    height: 0.375em;

    border: 0.125em solid #FFFFFF;
    border-top-style: none;
    border-right-style: none;
  }

  .md-checkbox>input[type="checkbox"]:disabled+label:before {
    border-color: rgba(0, 0, 0, 0.26);
  }

  .md-checkbox>input[type="checkbox"]:disabled:checked+label:before {
    background: rgba(0, 0, 0, 0.26);
  }

  /* Material Radio */
  .md-radio {
    margin: 16px 0;
  }

  .md-radio>input[type="radio"] {
    display: none;
  }

  .md-radio>input[type="radio"]:checked+label:before {
    border-color: var(--mdc-theme-secondary);
    animation: ripple 0.2s linear forwards;
  }

  .md-radio>input[type="radio"]:checked+label:after {
    transform: scale(1);
  }

  .md-radio>label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
  }

  .md-radio>label:before,
  .md-radio>label:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    transition: all .3s ease;
    transition-property: transform, border-color;
  }

  .md-radio>label:before {
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(var(--mdc-theme-on-surface), 0.54);
  }

  .md-radio>label:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    transform: scale(0);
    background: var(--mdc-theme-secondary);
  }

  .md-radio.md-radio-inline {
    display: inline-block;
  }

  /* Material Input Select */
  select.md-input {
    padding: 15px 4px 1px 10px;
  }

  select.md-input:hover {
    cursor: pointer;
  }
  select.md-input option, select.md-input optgroup
  {
    background-color: var(--mdc-theme-surface);
  }
  /* Material Inputs */

  select,
  textarea,
  input:not([type]),
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"] {
    outline: none;
  }

  select.md-input,
  textarea.md-input,
  input:not([type]).md-input,
  input[type="text"].md-input,
  input[type="password"].md-input,
  input[type="datetime"].md-input,
  input[type="datetime-local"].md-input,
  input[type="date"].md-input,
  input[type="month"].md-input,
  input[type="time"].md-input,
  input[type="week"].md-input,
  input[type="number"].md-input,
  input[type="email"].md-input,
  input[type="url"].md-input,
  input[type="search"].md-input,
  input[type="tel"].md-input,
  input[type="color"].md-input {
    -webkit-appearance: none;
    border-radius: 4px 4px 0 0;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    font: 400 15px / 18px "Google Sans", sans-serif;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 12px 4px 1px 13px;
    color: rgba(var(--mdc-theme-on-surface),1);
    background: rgba(var(--mdc-theme-on-surface),0.05);
    width: 100%;
    display: block;
  }

  .md-input-wrapper+* {
    margin-top: 10px;
  }

  .md-input-wrapper>input,
  .md-input-wrapper>textarea {
    caret-color: var(--mdc-theme-primary);
  }
  .md-input-wrapper.md-select::after {
    position: absolute;
    top: 14px;
    right: 7px;
    width: 18px;
    height: 24px;
    content: "\e313";
    font-family: "Material Icons";
    font-size: 18px;
    display: block;
    color: #727272;
    -webkit-transition: -webkit-transform 280ms;
    transition: -webkit-transform 280ms;
    transition: transform 280ms;
    transition: transform 280ms, -webkit-transform 280ms;
  }
  .content-before {
    position: absolute;
    z-index: -1;
    top: 47px;
    left: 0;
    width: 100%;
    height: 185px;
    -webkit-transition: 0.3s ease all;
    transition: 0.3s ease all;
    background: var(--mdc-theme-app-bar);
  }
  .md-input-wrapper.md-select.md-input-no-label.md-input-dense::after {
    top: 8px;
  }
  .uk-table.uk-table-hover tbody tr:hover,
  .uk-table.uk-table-hover > tr:hover {
    background-color: rgba(var(--mdc-theme-on-surface), .04);
  }
  .uk-table.uk-table-dense td,
  .uk-table.uk-table-dense th {
    padding: 6px 16px 6px 16px !important;
    height: 48px;
  }
  .datatable-header {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    flex-direction: inherit;
    justify-content: flex-start;
  }
  .datatable-row {
    white-space: nowrap;
  }
  .datatable-default-order-icon {
    opacity: 0;
  }

  .datatable-header:hover > .datatable-default-order-icon {
    opacity: 0.5;
  }
  /*@media (max-width: 599px){
    .uk-overflow-auto {
      -webkit-mask-image: linear-gradient(90deg,transparent,#000 20px,#000 90%,transparent);
    }
    }*/

    .md-input-wrapper-with-icon > select {
      padding-left: 48px !important;
      padding-right: 15px !important;
    }
    #admin-header > .uk-navbar-container > .uk-navbar-right {
      padding-right: 5px;
    }
    .md-input-wrapper .md-input-bar {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    .md-input-wrapper .md-input-bar:before,
    .md-input-wrapper .md-input-bar:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 0;
      height: 2px;
      background: var(--mdc-theme-primary);
      -webkit-transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .md-input-wrapper .md-input-bar:before {
      left: 50%;
    }

    .md-input-wrapper .md-input-bar:after {
      right: 50%;
    }

    .md-input-icon {
      z-index: 20;
      position: absolute;
      top: 15px;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      color: rgba(var(--mdc-theme-on-surface), .54);
    }

    .md-input-wrapper {
      border-radius: 4px 4px 0 0;
      background-color: transparent;
      display: inline-flex;
      position: relative;
      height: 56px;
      width: 100%;
    }

    .md-input-wrapper-helper-text {
      margin-bottom: 18px !important;
    }
    .md-input-no-label>input,
    .md-input-no-label>select {
      padding-top: 1px !important;
    }

    .md-input-dense {
      height: 52px;
    }

    .md-input-no-label.md-input-dense {
      height: 40px !important;
    }
    .md-input-wrapper-outlined{
      border-radius: 4px;
    }
    .md-input-wrapper.md-input-wrapper-outlined,
    .md-input-wrapper.md-input-wrapper-outlined:hover {
      height: 54px;
      background: var(--mdc-theme-surface);
    }

    .md-input-wrapper.md-input-wrapper-outlined.md-input-dense {
      height: 40px;
    }

    /*.md-input-wrapper.md-input-wrapper-outlined>input:hover,
    .md-input-wrapper.md-input-wrapper-outlined>select:hover {
      background: rgba(var(--mdc-theme-on-surface),0.05);
      border: 1px solid black;
      }*/

      .md-input-wrapper.md-input-wrapper-outlined.md-input-focus>select,
      .md-input-wrapper.md-input-wrapper-outlined.md-input-focus>input {
        background: var(--mdc-theme-surface);
        border: 2px solid var(--mdc-theme-primary);
      }

      .md-input-wrapper.md-input-wrapper-outlined>input,
      .md-input-wrapper.md-input-wrapper-outlined>select {
        border-radius: 4px;
        background: var(--mdc-theme-surface);
        border: 1px solid rgba(var(--mdc-theme-on-surface), .15);
      }
      .md-input-wrapper.md-input-wrapper-outlined:not(.md-input-no-label)>input,
      .md-input-wrapper.md-input-wrapper-outlined>select {
        padding: 12px 16px 14px;
      }
      .md-input-wrapper.md-input-wrapper-outlined>label {
        background: var(--mdc-theme-surface);
        padding: 0px 5px;
        right: auto;
      }
    /*.md-input-wrapper.md-input-wrapper-outlined:hover>label {
      background: rgba(var(--mdc-theme-on-surface),0.05);
      }*/
      .md-input-wrapper.md-input-wrapper-shaped > select,
      .md-input-wrapper.md-input-wrapper-shaped > input,
      .md-input-wrapper.md-input-wrapper-shaped {
        border-radius: 25px 25px 0 0;
      }

      .md-input-wrapper-outlined.md-input-wrapper-shaped>input,
      .md-input-wrapper-outlined.md-input-wrapper-shaped>select {
        border-radius: 25px
      }

      .md-input-wrapper.md-input-dense>label {
        font-size: 12px;
      }

      .md-input-wrapper:hover {
        background-color: rgba(var(--mdc-theme-on-surface),0.04);
        border-bottom-color: rgba(0, 0, 0, 0.87);
      }

      .md-input-wrapper>label {
        color: rgba(var(--mdc-theme-on-surface), .54);
        position: absolute;
        top: 16px;
        left: 13px;
        /* left: 4px; */
        right: 0;
        pointer-events: none;
        -webkit-transition: all 150ms ease-out;
        transition: all 150ms ease-out;
        text-align: left !important;
        font-size: 1rem;
      }

      .md-input-wrapper.md-input-wrapper-outlined.md-input-dense:not(.md-input-filled):not(.md-input-focus)>label {
        top: 10px;
      }
      .md-input-wrapper>p {
        position: absolute;
        top: 57px;
        margin: 0px 12px;
        font-size: .75rem;
        -webkit-font-smoothing: antialiased;
        font-weight: 400;
        letter-spacing: .03333em;
      }

      .md-input-filled>label,
      .md-input-focus>label {
  /* top: -6px;
  font-size: 12px; */
  top: 5px;
  font-size: 12px;
}

.md-input-wrapper-with-trailing-icon>.md-input-icon {
  right: 15px;
}

.md-input-wrapper-with-icon>.md-input-icon {
  left: 15px;
}

.md-input-wrapper.md-input-dense>.md-input-icon {
  top: 12px ;
}
.md-input-wrapper.md-input-dense.md-input-no-label>.md-input-icon {
  top: 7px !important;
}

.md-input-wrapper.md-input-dense>input[type=date] span.md-input-icon {
  top: 13px !important;
}
.md-input-wrapper.md-input-wrapper-outlined.md-input-dense>.md-input-icon {
  top: 7px;
}

.md-input-wrapper.md-input-dense>.md-input-icon>i {
  transform: scale(.8);
  -webkit-transform: scale(.8);
}

.md-input-wrapper>input,
.md-input-wrapper>textarea {
  caret-color: var(--mdc-theme-primary);
}

.md-input-wrapper-with-icon.md-input-dense>input {
  padding-left: 42px !important;
}

.md-input-wrapper-with-icon>input {
  caret-color: var(--mdc-theme-primary);
  padding-left: 48px !important;
  padding-right: 15px !important;
}

.md-input-wrapper-with-trailing-icon>input {
  caret-color: var(--mdc-theme-primary);
  padding-right: 48px !important;
  padding-left: 15px !important;
}

.md-input-focus {
  background-color: rgba(0, 0, 0, .09);
}

.md-input-focus>label {
  color: var(--mdc-theme-primary);
}

.md-input-focus .md-input-bar:before,
.md-input-focus .md-input-bar:after {
  width: 50%;
}

.md-input-wrapper-with-icon>label {
  left: 48px;
  right: auto;
}

.md-input-wrapper-with-trailing-icon>label {
  right: auto;
}

.md-input-wrapper-with-icon.md-input-dense>label {
  left: 42px;
  right: auto;
}

.md-input-wrapper-with-icon.md-input-wrapper-outlined.md-input-dense:not(.md-input-filled):not(.md-input-focus)>label {
  left: 38px;
  right: auto;
}

.md-input-wrapper-outlined.md-input-filled>label,
.md-input-wrapper-outlined.md-input-focus>label {
  left: 12px;
  top: -10px;
  right: auto;
}

/* before new */
.md-input-wrapper.md-input-textarea {
  display: block;
  height: auto;
  /*background: white;*/
}

/*input[type="date"].md-input,
input[type="datetime-local"].md-input {
  margin-top: 5px;
  }*/

  .md-input-wrapper.md-input-textarea {
    border-radius: 4px;
  }
/*.md-input-wrapper.md-input-textarea:not(.md-input-wrapper-outlined) {
  background-color: #f5f5f5;
  }*/
  .md-input-wrapper.md-input-textarea:not(.md-input-wrapper-outlined) >textarea {
    padding-top: 26px;
    top: 20;
    /* padding-top: 26px;*/
  /*position: relative;
  top: 20;*/
  resize: none;
  height: auto;
}

.md-input-wrapper.md-input-textarea.md-input-filled>label,
.md-input-wrapper.md-input-textarea.md-input-focus>label {
  left: 0px;
  top: 0px;
  padding: 3px 10px;
  font-size: 14px;
}
.md-input-wrapper.md-input-textarea:not(.md-input-wrapper-outlined):hover{
  background-color: rgba(var(--mdc-theme-on-surface),0.04);
  border-bottom-color: rgba(0, 0, 0, 0.87);
}

.md-input-wrapper.md-input-textarea.md-input-wrapper-outlined.md-input-filled>label,
.md-input-wrapper.md-input-textarea.md-input-wrapper-outlined.md-input-focus>label {
  left: 9px;
  top: -12px;
  padding: 2px 8px;
  font-size: 14px;
}
.md-input-wrapper.md-input-textarea.md-input-wrapper-outlined>textarea {
  padding: 14px 16px 14px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, .15);
  overflow: auto;
  resize: none;
  height: auto;
  background-color: var(--mdc-theme-surface);
}
.md-input-wrapper.md-input-textarea.md-input-wrapper-outlined:hover {
  height: auto;
}
.md-input-wrapper.md-input-textarea.md-input-wrapper-outlined>textarea:hover {
  border: 1px solid black;
}


.md-input-wrapper.md-input-textarea.md-input-wrapper-outlined.md-input-focus>textarea {
  border: 2px solid var(--mdc-theme-primary);
}

.md-input-wrapper.md-input-textarea.md-input-wrapper-outlined>label {
  background-color: var(--mdc-theme-surface);
  padding-right: 5px;
  /* margin-right: 20px; */
  right: auto;
}

.md-input-wrapper.md-input-textarea:not(.md-input-wrapper-outlined)>p {
  position: absolute;
  top:auto;
  bottom: -22px;
}
.md-input-wrapper.md-input-textarea.md-input-wrapper-outlined>p {
  position: relative;
  top: 4px;
}

/* Material Input Validation */

.md-input-danger>input.md-input,
.md-input-danger>input.md-input:focus,
.md-input-danger>select.md-input:focus,
.md-input-danger>textarea.md-input:focus {
  border-bottom-color: #e53935;
  border-color: #e53935;
  caret-color: #e53935;
}

.md-input-danger>label,
.md-input-danger>p,
.md-input-danger>span>i {
  color: #e53935;
}

.md-input-danger.md-input-filled>label,
.md-input-danger.md-input-filled>p,
.md-input-danger.md-input-filled>i {
  color: #e53935;
}

.md-input-danger.md-input-focus>label,
.md-input-danger.md-input-focus>p,
.md-input-danger.md-input-filled>label,
.md-input-danger.md-input-filled>p,
.md-input-danger.md-input-filled>span>i {
  color: #e53935;
}

.md-input-danger {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
}

.md-input-danger>.md-input-bar:before,
.md-input-danger>.md-input-bar:after {
  background-color: #e53935;
}

/* Material Notification */
.uk-notification-message {
  z-index: 1041;
  background: #323232;
  color: #fff;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 18px;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);

}

.uk-notification-message:hover .uk-notification-close {
  display: none;
}

.uk-notification-message-success {
  background: #659f13;
  color: #fff;
}

.uk-notification-message-danger {
  background: #e53935;
  color: #fff;
}

.uk-notification-message-warning {
  background: #e28327;
  color: #fff;
}

.uk-notification-message-info {
  background: var(--mdc-theme-primary);
  color: #fff;
}

.uk-notification .notification-action {
  float: right;
  text-transform: uppercase;
  color: #8bc34a;
  margin-left: 16px;
}

.uk-notification-close {
  top: 14px;
  right: 14px;
}

.uk-notification-message>div::after {
  content: "";
  display: table;
  clear: both;
}

.uk-notification-message>div::before {
  content: "";
  display: table-cell;
}

.uk-notification-message>div>a {
  padding: 3px;
  float: right;
}

.uk-notification-message>div>span {
  max-width: 229;
  float: left;
  padding: 3px;
}

/* Material Notification with other elements */

/* .uk-notification-message>div>a:hover{
  background-color: #323232 !important;
  } */
  /* Material Icons */
  h1>i.material-icons,
  h2>i.material-icons,
  h3>i.material-icons,
  h4>i.material-icons,
  h5>i.material-icons {
    top: 5;
    position: relative;
  }

  h1>i.fab,
  h2>i.fab,
  h3>i.fab,
  h4>i.fab,
  h5>i.fab {
    padding-top: 10;
    padding-right: 5;
  }

  .md-icon {
    font-size: 24px;
    line-height: 34px !important;
    height: 34px !important;
    color: rgba(var(--mdc-theme-on-surface), .54);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    -webkit-transition: background 280ms ease-out, color 280ms ease-out;
    transition: background 280ms ease-out, color 280ms ease-out;
    width: 34px !important;
    text-align: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
    background: none;
    padding: 0px;
  }

  .md-icon:hover,
  .md-icon:focus,
  .md-icon:active,
  .md-icon.active {
    color: rgba(var(--mdc-theme-on-surface), 1);
    text-decoration: none;
    background: rgba(0, 0, 0, 0.08);
    outline: none;
  }

  .md-icon+.md-icon {
    margin-left: 4px;
  }

  /* Material Cards */
  .uk-card {
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    background-color: var(--mdc-theme-surface);
  }
  .uk-card .outlined{
    border-radius: 4px;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border: 1px solid rgba(var(--mdc-theme-on-surface),0.12);
    background-color: var(--mdc-theme-surface);
  }
  .uk-card>* {
    border-radius: 4px;
  }
  .uk-card h1,
  .uk-card h2,
  .uk-card h3,
  .uk-card h4,
  .uk-card h5,
  .uk-card h6
  {
    color: rgb(var(--mdc-theme-on-surface));
  }
.uk-card p:not(.uk-text-meta),
  .uk-card .md-radio>label,
  .uk-card .md-switch>label
  {
    color: rgba(var(--mdc-theme-on-surface),0.8);
  }
  .uk-card-default .uk-card-footer {
    border-top: none;
  }

  .uk-card-media-top>img {
    border-radius: 4px 4px 0px 0px;
  }

  .uk-card-media-top {
    border-radius: 6px 6px 0px 0px;
  }

/* .uk-card:hover>.uk-card-body{
  background-color: #F5F5F5;
  border-radius: 4px 4px 4px 4px;
  } */
  .uk-card-media-top {
    background-color: black;
  }

  .md-card .md-card-toolbar-heading-text {
    font: 500 14px / 50px "Google Sans", sans-serif;
    color: rgb(var(--mdc-theme-on-surface));
    margin: 0;
    float: left;
    overflow: hidden;
    height: 48px;
  }

/* .uk-card:hover>.uk-card-media-top>img{
  opacity: 0.95;
  } */
  
  .cascade-card-content>.uk-card-body {
    padding: 1rem;
    background-color: var(--mdc-theme-surface);
  }

  .uk-card-footer,
  .cascade-card-content>.uk-card-footer {
    padding: 8px;
  }

  .uk-card-header {
    padding: 13px 15px;
  }

  .md-card-toolbar-actions {
    float: right;
  }

/* .uk-card-header-tab {
  padding: 0;
  border-bottom: none;
  }  */
  .cascade-card-wider {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: none;
  }

  .cascade-card-narrower {
    margin-top: 1.25rem;
    display: flex;
    flex-direction: column;
  }

  .cascade-card-wider>.cascade-card-content {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    margin-left: 4%;
    margin-right: 4%;
    border-radius: 0px 0px 4px 4px;
  }

  .cascade-card-wider.reverse>.cascade-card-content {
    position: relative;
    border-radius: 4px;
  }

  .cascade-card-wider>.cascade-card-overlay,
  .cascade-card>.cascade-card-overlay {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    border-radius: 4px;
    position: relative;
  }

  .cascade-card-narrower>.cascade-card-overlay {
    margin-left: 4%;
    margin-right: 4%;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    border-radius: 4px;
    margin-top: -1.25rem;
  }

  .cascade-card-overlay>img {
    border-radius: 4px;
  }

  .reverse>.cascade-card-content {
    margin-top: -1rem;
  }

  .material-card-fab {
    margin-top: -32px;
    padding-right: 25px;
    float: right;
  }

  .testimonial-card>.testimonial-card-header {
    height: 120px;
  }

  .testimonial-card>.testimonial-card-avatar {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 120px;
    margin-top: -60px;
    border: 5px solid #fff;
  }

  /* Utilities */

  .border-bottom {
    border-bottom: 1px solid #e5e5e5;
  }

  /* Material Tabs */
  .uk-tab {
    /* Configurado para Cards */
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;

    flex-wrap: nowrap;
  }

  .uk-tab::before {
    left: 0px;
  }

  .uk-tab>* {
    padding-left: 0px;
  }

  .uk-tab>li>a {
    -webkit-transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
    -moz-transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
    -o-transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
    transition: background-color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
  }

  .uk-tab>li>a>i.material-icons {
    font-size: 18px;
    vertical-align: -4px;
    margin-right: 6px;
  }

/*.uk-tab>li>a:hover,
.uk-tab>li.uk-active>a:hover {
  background-color: var(--mdc-theme-secondary-light) !important;
  }*/
  .uk-tab>li>a:hover:after,
  .uk-tab>li.uk-active>a:hover:after {
    display: block;
    content: " ";
    position: absolute;
    /*background: var(--mdc-theme-secondary);*/
    opacity: 0.2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .uk-tab>*>a{
    padding: 10px 16px;
  }
  .uk-tab>li>a:active:after,
  .uk-tab>li.uk-active>a:active:after {
    display: block;
    content: " ";
    position: absolute;
    /*background: var(--mdc-theme-secondary);*/
    opacity: 0.4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .uk-tab>li.uk-active>a {
    color: var(--mdc-theme-secondary);
    font-weight: 500;
    font-size: .875rem;
    border-width: 2px;
    text-transform: uppercase;
  }

  .uk-tab>.uk-active>a {
    border-color: var(--mdc-theme-secondary);
  }

  /* Material Modal */

  /* dialog modals */
  .uk-modal {
    z-index: 1010;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transition: opacity 250ms ease-out;
    transition: opacity 250ms ease-out;
    overflow: auto !important;
  }

  .uk-modal.uk-modal-no-backdrop {
    background: transparent;
  }

  .uk-modal-dialog {
    border-radius: 4px;
    -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    /* padding: 24px; */
    display: block !important;
    -webkit-animation: none !important;
    animation: none !important;
    -webkit-transition: opacity 280ms ease-in, -webkit-transform 280ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 280ms ease-in, -webkit-transform 280ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 280ms cubic-bezier(0.4, 0, 0.2, 1), opacity 280ms ease-in;
    transition: transform 280ms cubic-bezier(0.4, 0, 0.2, 1), opacity 280ms ease-in, -webkit-transform 280ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: scale(0);
    transform: scale(0);
    background-color: var(--mdc-theme-surface);
  }

  .uk-modal-dialog-blank {
    padding: 0;
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .uk-open .uk-modal-dialog {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .uk-modal-dialog .uk-modal-header {
    background: none;
    border-bottom: none;
    margin: 0px;
    padding: 24px 24px 12px;
    overflow: hidden;
  }

  .modal-overflow>.uk-modal-header {
    border-bottom: 1px solid rgba(var(--mdc-theme-on-surface), .12);
    padding: 15px 24px;
  }
  .uk-modal-title{
    color: rgb(var(--mdc-theme-on-surface));
  }
  .uk-modal-dialog .uk-modal-header .uk-modal-title {
    margin: 0;
    font: 500 18px / 28px "Google Sans", sans-serif;
  }

  .uk-modal-dialog .uk-modal-header .uk-modal-title span {
    font-size: 16px;
    display: block;
    color: #727272;
  }

  .uk-modal-dialog .uk-modal-header .material-icons {
    font-size: 24px;
    vertical-align: -4px;
    cursor: default;
  }

  .uk-modal-body {
    padding: 12px 24px 24px;
  }

  .uk-modal-dialog .uk-modal-footer {
    padding: 6px;
    margin: 3px;
    background: var(--mdc-theme-surface);
    border-top: none;
  }

  .modal-overflow>.uk-modal-footer {
    border-top: 1px solid rgba(var(--mdc-theme-on-surface), .12);
    margin: 0px 3px 3px;
    padding: 8px 5px;
  }

  .uk-modal-dialog .uk-modal-footer:before,
  .uk-modal-dialog .uk-modal-footer:after {
    content: " ";
    display: table;
  }

  .uk-modal-dialog .uk-modal-footer:after {
    clear: both;
  }

  .uk-modal-dialog .uk-modal-footer:before,
  .uk-modal-dialog .uk-modal-footer:after {
    content: " ";
    display: table;
  }

  .uk-modal-dialog .uk-modal-footer:after {
    clear: both;
  }

  .uk-modal-dialog .uk-modal-footer .md-icon-btn {
    margin-top: 2px;
  }

  .uk-modal-dialog .uk-modal-caption {
    bottom: 16px;
    margin: 0 32px;
  }

  .uk-modal-card-fullscreen {
    overflow: hidden !important;
  }

  .uk-modal-card-fullscreen .uk-modal-close {
    float: left;
    margin: 8px 8px 0 0;
    display: block;
  }

  .uk-modal-card-fullscreen>.uk-modal-dialog>.md-card>.md-card-toolbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    z-index: 10;
    background-color: var(--mdc-theme-surface);
  }

  .uk-modal-card-fullscreen>.uk-modal-dialog>.md-card>.md-card-content {
    position: absolute;
    top: 48px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    background-color: var(--mdc-theme-surface);
  }

  .md-card .md-card-toolbar .md-card-toolbar-actions {
    float: right;
    padding-top: 7px;
  }

  .md-card .md-card-toolbar .md-card-toolbar-actions .uk-open .md-card-toolbar-icon {
    background: rgba(0, 0, 0, 0.085);
    color: #212121;
  }

  .md-card .md-card-toolbar .md-card-toolbar-actions .md-card-dropdown {
    display: inline-block;
    position: relative;
  }

  .md-card .md-card-toolbar {
    height: 48px;
    padding: 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background: #fff;
  }

  /* Material Buttons */
  .md-btn {
    background: #fff;
    border: none;
    border-radius: 2px;
  /*-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);*/
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  -webkit-transition: -webkit-box-shadow .28s cubic-bezier(.4, 0, .2, 1);
  transition: -webkit-box-shadow .28s cubic-bezier(.4, 0, .2, 1);
  min-height: 31px;
  min-width: 70px;
  padding: 2px 16px;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  -webkit-transition: all 280ms ease;
  transition: all 280ms ease;
  color: #212121;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  font: 500 14px / 31px "Google Sans", sans-serif !important;
  border-radius: 4px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
            supported by Chrome, Edge, Opera and Firefox */
          }

          .md-btn:hover,
          .md-btn:focus,
          .uk-button-dropdown.uk-open>.md-btn {
            background: #fff;
            outline: none;
            text-decoration: none;
            color: #212121;
            -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
          }

          .md-btn:active,
          .md-btn.uk-active,
          .uk-button-dropdown.uk-open>.md-btn {
            -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
          }

          .md-btn-flat {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            background: none;
          }

          .md-btn-text {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            background: none;
          }
          .md-btn.md-btn-text[disabled] {
            color: rgba(var(--mdc-theme-on-surface), .54);
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            background: none;
          }

          .md-btn-text:hover,
          .md-btn-text:focus {
            background: rgba(153, 153, 153, 0.2);
          }

          .md-btn-text:active,
          .md-btn-text.uk-active {
            background: rgba(153, 153, 153, 0.4);
          }

          .md-btn-text.disabled {
            background: none !important;
          }

          .md-btn-shaped {
            border-radius: 25px;
          }

          .md-btn-outlined {
            background-color: transparent;
            border-style: solid;
            padding: 0 14px;
            border-width: 2px;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            background: none;
          }
          .md-btn.md-btn-outlined[disabled] {
            background-color: transparent;
          }
          .md-btn-outlined:hover{
            background: none;
          }

          .md-btn-primary,
          .md-btn-primary:hover,
          .md-btn-primary:focus,
          .md-btn-primary:active,
          .md-btn-primary.uk-active,
          .md-btn-primary:not(.md-btn-text):not(.md-btn-outlined)>i {
            color: var(--mdc-theme-on-primary);
          }

          .md-btn-primary,
          .md-btn-primary:hover,
          .md-btn-primary:focus,
          .md-btn-primary:active,
          .md-btn-primary.uk-active {
            background: var(--mdc-theme-primary);
          }

          .md-btn-secondary,
          .md-btn-secondary:hover,
          .md-btn-secondary:focus,
          .md-btn-secondary:active,
          .md-btn-secondary.uk-active,
          .md-btn-secondary:not(.md-btn-text):not(.md-btn-outlined)>i {
            color: var(--mdc-theme-on-secondary);
          }

          .md-btn-secondary,
          .md-btn-secondary:hover,
          .md-btn-secondary:focus,
          .md-btn-secondary:active,
          .md-btn-secondary.uk-active {
            background: var(--mdc-theme-secondary);
          }

          .md-btn-text.md-btn-primary,
          .md-btn-text.md-btn-primary:hover,
          .md-btn-text.md-btn-primary:focus,
          .md-btn-text.md-btn-primary:active {
            color: var(--mdc-theme-primary);
            background-color: transparent;
          }

          .md-btn-text.md-btn-secondary,
          .md-btn-text.md-btn-secondary:hover,
          .md-btn-text.md-btn-secondary:focus,
          .md-btn-text.md-btn-secondary:active {
            color: var(--mdc-theme-secondary);
            background-color: transparent;
          }

/*.md-btn-text.md-btn-secondary:hover:after {
  display:block;
  content:" ";
  position:absolute;
  background: var(--mdc-theme-secondary);
  opacity: 0.2;
  top:0;
  left:0;
  right:0;
  bottom:0;
  }*/

  .md-btn-outlined.md-btn-primary,
  .md-btn-outlined.md-btn-primary:hover,
  .md-btn-outlined.md-btn-primary:focus,
  .md-btn-outlined.md-btn-primary:active {
    color: var(--mdc-theme-primary);
    background-color: transparent;
  }

 /* .md-btn-outlined.md-btn-primary:hover::before {
    background: var(--mdc-theme-primary);
    opacity: 0.04;
    }*/
    .md-btn-outlined.md-btn-secondary,
    .md-btn-outlined.md-btn-secondary:hover,
    .md-btn-outlined.md-btn-secondary:focus,
    .md-btn-outlined.md-btn-secondary:active {
      color: var(--mdc-theme-secondary);
      background-color: transparent;
    }

    .md-btn[disabled] {
      color: rgba(var(--mdc-theme-on-surface), .54);
      background: rgba(var(--mdc-theme-on-surface),0.1);
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      cursor: default;
      pointer-events: none;
    }

    .md-btn>i.material-icons {
      /* margin-top: 5px; */
      font-size: 18px;
      vertical-align: -4px;
    }

    .md-btn-icon-default>i,
    .md-btn-icon>i {
      font-size: 18px;
      margin-right: 8px;
      vertical-align: -2px;
    }

    .md-btn-icon:not(.md-btn-mini):not(.md-btn-small)>i,
    .md-btn-icon:not(.md-btn-mini):not(.md-btn-small)>span {
      margin-top: 8px;
    }

    .md-btn-icon.no-text.md-btn-small>i,
    .md-btn-icon.no-text.md-btn-small>span {
      margin-top: 4px;
    }

    .md-btn-icon.no-text>i,
    .md-btn-icon.no-text>span {
      position: relative;
      margin-right: 0px !important;
    }

    .md-btn-icon:not(.md-btn-mini):not(.md-btn-small).no-text>i:not(.material-icons),
    .md-btn-icon:not(.md-btn-mini):not(.md-btn-small).no-text>span {
      top: -4px;
    }

    .md-btn-mini {
      line-height: 21px !important;
      min-width: 12px;
      font-size: 10px !important;
      min-height: 24px;
    }

    .md-btn-small {
      line-height: 27px !important;
      min-width: 14px;
      font-size: 11px !important;
    }

    .md-btn-large {
      line-height: 42px !important;
      font-size: 16px !important;
    }

    .md-btn-icon-large,
    .md-btn-icon.md-btn-large {
      min-width: 72px;
    }

    .md-btn-icon-large>i,
    .md-btn-icon.md-btn-large>i,
    .md-btn-icon.md-btn-large>span {
      font-size: 24px;
      margin-right: 12px;
      vertical-align: -3px;
    }

    .md-btn-icon.md-btn-large>i.material-icons {
      vertical-align: -6px;
    }

    .md-btn+.md-btn {
      margin-left: 8px;
    }

    .md-btn-block {
      width: 100%;
    }

    .md-btn-block+.md-btn-block {
      margin-left: 0;
      margin-top: 12px;
    }

    .md-btn+.md-btn-group {
      margin-left: 16px;
    }

    .md-btn-facebook {
      background: #3b5998 !important;
    }

    .md-btn-twitter {
      background: #00aced !important;
    }

    .md-btn-gplus {
      background: #db4437 !important;
    }

    .md-btn-youtube {
      background: #ff0000 !important;
    }

    .md-btn-instagram {
      background: #c32aa3 !important;
    }

    .md-btn-pinterest {
      background: #bd081c !important;
    }

    .md-btn-linkedin {
      background: #007bb5 !important;
    }

    .md-btn-snapchat {
      background: #fffc00 !important;
    }

    .md-btn-whatsapp {
      background: #25d366 !important;
    }

    .md-btn-tumblr {
      background: #35465d !important;
    }

    .md-btn-reddit {
      background: #ff4500 !important;
    }

    .md-btn-spotify {
      background: #1ed760 !important;
    }

    .md-btn-apple {
      background: #a6b1b7 !important;
    }

    .md-btn-amazon {
      background: #ff9900 !important;
    }

    .md-btn-vimeo {
      background: #1ab7ea !important;
    }

    .md-btn-skype {
      background: #00aff0 !important;
    }

    .md-btn-dribbble {
      background: #ea4c89 !important;
    }

    .md-btn-slack {
      background: #4d394b !important;
    }

    .md-btn-yahoo {
      background: #430297 !important;
    }

    .md-btn-soundcloud {
      background: #ff5500 !important;
    }

    .md-btn-steam {
      background: #171A21 !important;
    }

    .md-btn-github {
      background: #000000 !important;
    }

    .md-btn-facebook,
    .md-btn-twitter,
    .md-btn-gplus,
    .md-btn-youtube,
    .md-btn-instagram,
    .md-btn-pinterest,
    .md-btn-linkedin,
    .md-btn-whatsapp,
    .md-btn-tumblr,
    .md-btn-reddit,
    .md-btn-spotify,
    .md-btn-apple,
    .md-btn-amazon,
    .md-btn-vimeo,
    .md-btn-skype,
    .md-btn-dribbble,
    .md-btn-slack,
    .md-btn-yahoo,
    .md-btn-soundcloud,
    .md-btn-steam,
    .md-btn-github,
    .md-btn-facebook>i,
    .md-btn-twitter>i,
    .md-btn-gplus>i,
    .md-btn-youtube>i,
    .md-btn-instagram>i,
    .md-btn-pinterest>i,
    .md-btn-linkedin>i,
    .md-btn-whatsapp>i,
    .md-btn-tumblr>i,
    .md-btn-reddit>i,
    .md-btn-spotify>i,
    .md-btn-apple>i,
    .md-btn-amazon>i,
    .md-btn-vimeo>i,
    .md-btn-skype>i,
    .md-btn-dribbble>i,
    .md-btn-slack>i,
    .md-btn-yahoo>i,
    .md-btn-soundcloud>i,
    .md-btn-steam>i,
    .md-btn-github>i {
      color: #fff !important;
    }

    .md-btn-group {
      display: inline-block;
      vertical-align: top;
      position: relative;
      font-size: 0;
      white-space: nowrap;
    }

    .md-btn-group .md-btn {
      vertical-align: top;
      margin-left: 0 !important;
    }

    .md-btn-group .md-btn:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .md-btn-group .md-btn:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    .md-btn-group .md-btn:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    /* Material Buttons FAB */
    /* Material Badge */
    .uk-badge {
      margin: 4px;
      padding: 7px 12px;
      font-size: .875rem;
      line-height: 1.25rem;
      font-weight: 400;
    }

    .uk-badge-material {
      background: var(--mdc-theme-secondary);
      color: var(--mdc-theme-on-secondary);
      font-size: 11px;
      font-weight: 400;
      position: absolute;
      left: 24px;
      top: 2px;
      min-width: 20px;
      height: 20px;
      padding: 1px 4px;
      /* line-height: 14px; */
      border-radius: 25px;
    }

    /* Material FAB */

    .md-fab {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: var(--mdc-theme-surface);
      color: rgba(var(--mdc-theme-on-surface),1);
      display: block;
      -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
      -webkit-transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
      border: none;
      position: relative;
      text-align: center;
      cursor: pointer;
      -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
            supported by Chrome, Edge, Opera and Firefox */
    }

    .md-fab.md-fab-mini {
      width: 40;
      height: 40;
    }

    .md-fab.md-fab-mini>i {
      font-size: 24px;
      line-height: 40px;
    }

    .md-fab.md-fab-extended.md-fab-icon-right {
      padding: 0px 12px 0px 20px;
    }

    .md-fab.md-fab-extended {
      padding: 14px 20px 0px 20px;
      height: 48px;
      width: auto;
      border-radius: 24px;
      letter-spacing: .08929em;
      vertical-align: middle;
      display: inline-block;
      text-transform: uppercase;
      color: rgba(var(--mdc-theme-on-surface), .54);
    }

    .md-fab.md-fab-extended>i {
      line-height: 47px;
      margin-left: 12px;
    }

    .md-fab.md-fab-extended.md-fab-icon-right>i {
      line-height: 47px;
      margin-left: 12px;
      display: inline;
      position: relative;
    }

    .md-fab.md-fab-extended:not(.md-fab-icon-right)>span {
      padding-left: 28px;
    }

    .md-fab.md-fab-extended.md-fab-icon-right>span {
      vertical-align: 6px;
    }

    .md-fab:focus {
      outline: none;
    }

    .md-fab:hover,
    .md-fab:focus {
      -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
    }

    .md-fab:active {
      -webkit-box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12);
      box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12);
    }

    .md-fab.md-fab-mini {
      width: 40;
      height: 40;
    }

    .md-fab.md-fab-mini>i {
      font-size: 24px;
      line-height: 40px;
    }

    .md-fab>i {
      font-size: 24px;
      line-height: 57px;
      height: inherit;
      width: inherit;
      position: absolute;
      left: 0;
      top: 0;
      color: rgba(var(--mdc-theme-on-surface), .54);
    }

    .md-fab.md-fab-small {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }

    .md-fab.md-fab-small>i {
      line-height: 48px;
      height: inherit;
      width: inherit;
      font-size: 24px;
    }

    .md-fab-speed-dial .md-fab-action-close {
      display: none;
    }

    .md-fab-wrapper {
      position: fixed;
      bottom: 24px;
      right: 24px;
      z-index: 970;
      -webkit-transition: margin 280ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: margin 280ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    @media only screen and (max-width: 767px) {
      .md-fab-wrapper {
        bottom: 20px;
        right: 20px;
      }
    }

    .md-fab-wrapper>.md-fab+.md-fab {
      margin-top: 16px;
    }

    .md-fab-wrapper.md-fab-in-card {
      position: absolute;
    }

    .md-fab-speed-dial .md-fab-wrapper-small {
      position: absolute;
      bottom: 80px;
      right: 5px;
      min-height: 48px;
      width: 48px;
      z-index: -1;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0;
      position: absolute;
      right: 0;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-child(1) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-last-child(2) {
      bottom: 64px;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-child(2) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-last-child(3) {
      bottom: 128px;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-child(3) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-last-child(4) {
      bottom: 192px;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-child(4) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-last-child(5) {
      bottom: 256px;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-child(5) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-last-child(6) {
      bottom: 320px;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-child(6) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-last-child(7) {
      bottom: 384px;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-child(7) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-last-child(8) {
      bottom: 448px;
    }

    .md-fab-speed-dial .md-fab-wrapper-small .md-fab-small:nth-child(8) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
    }

    .md-fab-speed-dial.md-fab-active .md-fab-small {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
      z-index: 10;
    }

    .md-fab-speed-dial.md-fab-active .md-fab-small:nth-last-child(1) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
    }

    .md-fab-speed-dial.md-fab-active .md-fab-small:nth-last-child(2) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
    }

    .md-fab-speed-dial.md-fab-active .md-fab-small:nth-last-child(3) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
    }

    .md-fab-speed-dial.md-fab-active .md-fab-small:nth-last-child(4) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
    }

    .md-fab-speed-dial.md-fab-active .md-fab-small:nth-last-child(5) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
    }

    .md-fab-speed-dial.md-fab-active .md-fab-small:nth-last-child(6) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
    }

    .md-fab-speed-dial.md-fab-active .md-fab-small:nth-last-child(7) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
    }

    .md-fab-speed-dial.md-fab-active .md-fab-small:nth-last-child(8) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small {
      position: absolute;
      right: 80px;
      bottom: 8px;
      min-height: 48px;
      width: 48px;
      z-index: -1;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0;
      position: absolute;
      right: 0;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-child(1) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-last-child(2) {
      right: 64px;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-child(2) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-last-child(3) {
      right: 128px;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-child(3) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-last-child(4) {
      right: 192px;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-child(4) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-last-child(5) {
      right: 256px;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-child(5) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-last-child(6) {
      right: 320px;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-child(6) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-last-child(7) {
      right: 384px;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-child(7) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-last-child(8) {
      right: 448px;
    }

    .md-fab-speed-dial-horizontal .md-fab-wrapper-small .md-fab-small:nth-child(8) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
    }

    .md-fab-speed-dial-horizontal.md-fab-active .md-fab-small {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
      z-index: 10;
    }

    .md-fab-speed-dial-horizontal.md-fab-active .md-fab-small:nth-last-child(1) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 50ms;
    }

    .md-fab-speed-dial-horizontal.md-fab-active .md-fab-small:nth-last-child(2) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
    }

    .md-fab-speed-dial-horizontal.md-fab-active .md-fab-small:nth-last-child(3) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 150ms;
    }

    .md-fab-speed-dial-horizontal.md-fab-active .md-fab-small:nth-last-child(4) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
    }

    .md-fab-speed-dial-horizontal.md-fab-active .md-fab-small:nth-last-child(5) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 250ms;
    }

    .md-fab-speed-dial-horizontal.md-fab-active .md-fab-small:nth-last-child(6) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
    }

    .md-fab-speed-dial-horizontal.md-fab-active .md-fab-small:nth-last-child(7) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 350ms;
    }

    .md-fab-speed-dial-horizontal.md-fab-active .md-fab-small:nth-last-child(8) {
      -webkit-transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
      transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
    }

    .md-fab-speed-dial-horizontal[data-fab-hover] .md-fab {
      display: inline-block;
      vertical-align: bottom;
    }

    .md-fab-speed-dial-horizontal[data-fab-hover] .md-fab-wrapper-small {
      display: none;
      min-height: 48px;
      width: auto;
      z-index: -1;
      white-space: nowrap;
      padding-bottom: 8px;
      vertical-align: bottom;
      position: static;
      right: auto;
      bottom: auto;
    }

    .md-fab-speed-dial-horizontal[data-fab-hover] .md-fab-wrapper-small .md-fab-small {
      position: static;
      right: auto;
      display: inline-block;
      vertical-align: bottom;
    }

    .md-fab-speed-dial-horizontal[data-fab-hover] .md-fab-wrapper-small .md-fab-small+.md-fab-small {
      margin-left: 16px;
    }

    .md-fab-speed-dial-horizontal[data-fab-hover] .md-fab-wrapper-small .md-fab-small:last-child {
      margin-right: 16px;
    }

    .md-fab-speed-dial-horizontal[data-fab-hover].md-fab-over .md-fab-wrapper-small {
      display: inline-block;
    }

    .md-fab-speed-dial[data-fab-hover] .md-fab-wrapper-small {
      margin-left: 4px;
      position: static;
      display: none;
      bottom: auto;
      right: auto;
    }

    .md-fab-speed-dial[data-fab-hover] .md-fab-wrapper-small .md-fab-small {
      position: static;
      bottom: auto;
    }

    .md-fab-speed-dial[data-fab-hover] .md-fab-wrapper-small .md-fab-small+.md-fab-small {
      margin-top: 16px;
    }

    .md-fab-speed-dial[data-fab-hover] .md-fab-wrapper-small .md-fab-small:last-child {
      margin-bottom: 16px;
    }

    .md-fab-speed-dial[data-fab-hover].md-fab-over .md-fab-wrapper-small {
      display: block;
    }

    .md-fab-toolbar {
      -webkit-transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
      cursor: default;
    }

    .md-fab-toolbar>i {
      cursor: pointer;
    }

    .md-fab-toolbar-actions {
      visibility: hidden;
      white-space: nowrap;
      padding: 0 16px;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .md-fab-toolbar-actions>a,
    .md-fab-toolbar-actions>button {
      display: block;
      float: left;
      opacity: 0;
      margin: 0 0 0 16px;
      height: 64px;
      width: 48px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
    }

    .md-fab-toolbar-actions>a:first-child,
    .md-fab-toolbar-actions>button:first-child {
      margin-left: 0;
    }

    .md-fab-toolbar-actions .material-icons {
      font-size: 24px;
      line-height: 57px;
      color: var(--mdc-theme-on-secondary);
    }

    .md-fab-toolbar.md-fab-animated {
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      border-radius: 4px;
    }

    .md-fab-toolbar.md-fab-animated>i {
      display: none;
    }

    .md-fab-toolbar.md-fab-active .md-fab-toolbar-actions {
      visibility: visible;
    }

    .md-fab-toolbar.md-fab-active .md-fab-toolbar-actions>a,
    .md-fab-toolbar.md-fab-active .md-fab-toolbar-actions>button {
      opacity: 1;
    }

    .md-fab-toolbar.md-fab-small .md-fab-toolbar-actions>a,
    .md-fab-toolbar.md-fab-small .md-fab-toolbar-actions>button {
      height: 48px;
      width: 36px;
      padding: 0;
      margin: 0 0 0 8px;
    }

    .md-fab-toolbar.md-fab-small .md-fab-toolbar-actions>a:first-child,
    .md-fab-toolbar.md-fab-small .md-fab-toolbar-actions>button:first-child {
      margin-left: 0;
    }

    .md-fab-toolbar.md-fab-small .md-fab-toolbar-actions .material-icons {
      font-size: 24px;
      line-height: 48px;
      height: inherit;
    }

    .md-fab-sheet {
      -webkit-transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
      transition: all 280ms cubic-bezier(0.4, 0, 0.2, 1);
      cursor: default;
    }

    .md-fab-sheet>i {
      cursor: pointer;
    }

    .md-fab-sheet-actions {
      visibility: hidden;
      white-space: nowrap;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 4px 0;
    }

    .md-fab-sheet-actions>a {
      display: block;
      opacity: 0;
      padding: 4px 16px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font: 400 16px / 32px "Google Sans", sans-serif;
      text-align: left;
    }

    .md-fab-sheet-actions>a,
    .md-fab-sheet-actions>a:hover {
      color: var(--mdc-theme-on-secondary);
    }

    .md-fab-sheet-actions .material-icons {
      font-size: 24px;
      margin-right: 8px;
      vertical-align: -6px;
      color: var(--mdc-theme-on-secondary);
    }

    .md-fab-sheet.md-fab-animated {
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      border-radius: 4px;
    }

    .md-fab-sheet.md-fab-animated>i {
      display: none;
    }

    .md-fab-sheet.md-fab-active .md-fab-sheet-actions {
      visibility: visible;
    }

    .md-fab-sheet.md-fab-active .md-fab-sheet-actions>a {
      opacity: 1;
    }

    .sidebar_secondary_active .md-fab-wrapper {
      margin-right: 264px;
    }

    @media only screen and (max-width: 767px) {
      .sidebar_secondary_active .md-fab-wrapper {
        margin-right: 280px;
      }
    }

    .md-fab.md-fab-primary {
      background: var(--mdc-theme-secondary);
      color: var(--mdc-theme-on-secondary);
    }

    .md-fab.md-fab-primary>i {
      color: var(--mdc-theme-on-secondary);
    }

    /* Material Expansion Panel*/
    .uk-accordion>li.uk-open {
      border: 1
    }

    .uk-accordion>li {
      border-style: solid;
      border-radius: 4px;
      border-color: rgba(153, 153, 153, .2);
    }

    .uk-accordion>li.uk-open {
      margin: 16px 0;
    }

    .uk-accordion-title {
      border-radius: 0;
      border: none;
      margin: 0 0 4px;
      font-size: 15px;
      font-weight: 400;
      padding: 8px 24px 8px 16px;
      position: relative;
      color: rgba(var(--mdc-theme-on-surface),0.8);
    }
    .uk-accordion-title:focus, .uk-accordion-title:hover{
      color: rgba(var(--mdc-theme-on-surface),1);
    }
    .uk-accordion>:nth-child(n+2) {
      margin-top: 0px;
    }

    .uk-accordion-title:after {
      content: '\e313';
      font-family: "Material Icons";
      font-size: 18px;
      position: absolute;
      top: 8px;
      right: 12px;
      display: block;
      color: #727272;
      -webkit-transition: -webkit-transform 280ms;
      transition: -webkit-transform 280ms;
      transition: transform 280ms;
      transition: transform 280ms, -webkit-transform 280ms;
    }

    .uk-open>.uk-accordion-title:before,
    .uk-accordion-title:before {
      background-image: none;
    }

    .uk-open>.uk-accordion-title:after {
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }

    .uk-accordion-content {
      padding: 0px 16px 16px 16px;
      margin-top: 0px;
    }

    /* Material Tables */
    .uk-table {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, .12);
      border-collapse: collapse;
    }

    .uk-table thead th {
      vertical-align: middle;
    }

    .uk-table thead th,
    .uk-table tfoot td,
    .uk-table tfoot th {
      -webkit-font-smoothing: antialiased;
      font-size: .875rem;
      line-height: 1.375rem;
      font-weight: 500;
      letter-spacing: .0071428571em;
      text-decoration: inherit;
      text-transform: inherit;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      text-align: left;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 56px;
      color: rgba(var(--mdc-theme-on-surface), .87);
    }

    .uk-table td {
      height: 52px;
    }

    .uk-table td {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: .875rem;
      line-height: 1.25rem;
      font-weight: 400;
      color: rgba(var(--mdc-theme-on-surface), .87);
      letter-spacing: .0178571429em;
      text-decoration: inherit;
      text-transform: inherit;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      vertical-align: middle;
    }

    .uk-table tfoot td,
    .uk-table tfoot th {
      border-top: 2px solid rgba(0, 0, 0, 0.12);
      border-bottom: none;
    }

    .uk-table-striped tbody tr:nth-of-type(odd) {
      background: rgba(0, 0, 0, 0.085);
    }

    .uk-table-hover tbody tr:hover {
      background-color: rgba(var(--mdc-theme-on-surface), .04);
    }

    .uk-table td,
    .uk-table th {
      padding-right: 16px;
      padding-left: 16px;
      border-bottom: 1px solid rgba(var(--mdc-theme-on-surface), .1);
    }

    /* Plugins */
    .touchscroll {
      overflow-y: auto !important;
      -webkit-overflow-scrolling: touch;
    }
    /* md butons */
    .md-btn.md-btn-primary.ripple-surface:not(.md-btn-text):not(.md-btn-outlined)::before,
    .md-btn.md-btn-primary.ripple-surface:not(.md-btn-text):not(.md-btn-outlined)::after {
      background-color: var(--mdc-theme-on-primary,#fff);
    }
    .md-btn.md-btn-secondary.ripple-surface:not(.md-btn-text):not(.md-btn-outlined)::before,
    .md-btn.md-btn-secondary.ripple-surface:not(.md-btn-text):not(.md-btn-outlined)::after {
      background-color: var(--mdc-theme-on-secondary,#fff);
    }
    .md-fab.ripple-surface::before,
    .md-fab.ripple-surface::after {
      background-color: var(--mdc-theme-on-secondary,#fff);
    }

    /* Material Components Ripple */
    .ripple-surface {
      --mdc-ripple-fg-size: 0;
      --mdc-ripple-left: 0;
      --mdc-ripple-top: 0;
      --mdc-ripple-fg-scale: 1;
      --mdc-ripple-fg-translate-end: 0;
      --mdc-ripple-fg-translate-start: 0;
      -webkit-tap-highlight-color: rgba(var(--mdc-theme-on-surface), 0);
      position: relative;
      outline: none;
      overflow: hidden;
    }
    .md-btn.mdc-ripple-upgraded:before {
      -webkit-transform: scale(var(--mdc-ripple-fg-scale,1));
      -ms-transform: scale(var(--mdc-ripple-fg-scale,1));
      transform: scale(var(--mdc-ripple-fg-scale,1));
    }
    @-webkit-keyframes mdc-ripple-fg-radius-in {
      from {
        -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
        transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
      }
      to {
        -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
        transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
      }
    }

    @keyframes mdc-ripple-fg-radius-in {
      from {
        -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
        transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
      }
      to {
        -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
        transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
      }
    }
    @-webkit-keyframes mdc-ripple-fg-opacity-in {
      from {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        opacity: 0;
      }
      to {
        opacity: var(--mdc-ripple-fg-opacity, 0);
      }
    }
    @keyframes mdc-ripple-fg-opacity-in {
      from {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        opacity: 0;
      }
      to {
        opacity: var(--mdc-ripple-fg-opacity, 0);
      }
    }
    @-webkit-keyframes mdc-ripple-fg-opacity-out {
      from {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        opacity: var(--mdc-ripple-fg-opacity, 0);
      }
      to {
        opacity: 0;
      }
    }
    @keyframes mdc-ripple-fg-opacity-out {
      from {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        opacity: var(--mdc-ripple-fg-opacity, 0);
      }
      to {
        opacity: 0;
      }
    }
    .ripple-surface::before, .ripple-surface::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: "";
    }
    .ripple-surface::before {
      transition: opacity 15ms linear, background-color 15ms linear;
      z-index: 1;
    }
    .ripple-surface.mdc-ripple-upgraded::before {
      -webkit-transform: scale(var(--mdc-ripple-fg-scale, 1));
      transform: scale(var(--mdc-ripple-fg-scale, 1));
    }
    .ripple-surface.mdc-ripple-upgraded::after {
      top: 0;
      /* @noflip */
      left: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
      -webkit-transform-origin: center center;
      transform-origin: center center;
    }
    .ripple-surface.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      /* @noflip */
      left: var(--mdc-ripple-left, 0);
    }
    .ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
      -webkit-animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
      animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
    }
    .ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
      -webkit-animation: mdc-ripple-fg-opacity-out 150ms;
      animation: mdc-ripple-fg-opacity-out 150ms;
      -webkit-transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
    }
    .ripple-surface::before, .ripple-surface::after {
      background-color: rgba(var(--mdc-theme-on-surface), 1);
    }
    .ripple-surface:hover::before {
      opacity: 0.04;
    }
    .ripple-surface.mdc-ripple-upgraded--background-focused::before, .ripple-surface:not(.mdc-ripple-upgraded):focus::before {
      transition-duration: 75ms;
      opacity: 0.12;
    }
    .ripple-surface:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear;
    }
    .ripple-surface:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.12;
    }
    .ripple-surface.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.12;
    }
    .ripple-surface::before, .ripple-surface::after {
      top: calc(50% - 100%);
      /* @noflip */
      left: calc(50% - 100%);
      width: 200%;
      height: 200%;
    }
    .ripple-surface.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
    }
    .ripple-surface[data-mdc-ripple-is-unbounded] {
      overflow: visible;
    }
    .ripple-surface[data-mdc-ripple-is-unbounded]::before, .ripple-surface[data-mdc-ripple-is-unbounded]::after {
      top: calc(50% - 50%);
      /* @noflip */
      left: calc(50% - 50%);
      width: 100%;
      height: 100%;
    }
    .ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::before, .ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      /* @noflip */
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
    }
    .ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%);
    }
    .ripple-surface--primary::before, .ripple-surface--primary::after {
      background-color: #6200ee;
      /* @alternate */
      background-color: var(--mdc-theme-primary, #6200ee);
    }
    .ripple-surface--primary:hover::before {
      opacity: 0.04;
    }
    .ripple-surface--primary.mdc-ripple-upgraded--background-focused::before, .ripple-surface--primary:not(.mdc-ripple-upgraded):focus::before {
      transition-duration: 75ms;
      opacity: 0.12;
    }
    .ripple-surface--primary:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear;
    }
    .ripple-surface--primary:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.12;
    }
    .ripple-surface--primary.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.12;
    }
    .ripple-surface--accent::before, .ripple-surface--accent::after {
      background-color: #018786;
      /* @alternate */
      background-color: var(--mdc-theme-secondary, #018786);
    }
    .ripple-surface--accent:hover::before {
      opacity: 0.04;
    }
    .ripple-surface--accent.mdc-ripple-upgraded--background-focused::before, .ripple-surface--accent:not(.mdc-ripple-upgraded):focus::before {
      transition-duration: 75ms;
      opacity: 0.12;
    }
    .ripple-surface--accent:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear;
    }
    .ripple-surface--accent:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.12;
    }
    .ripple-surface--accent.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.12;
    }
    ::-webkit-scrollbar {
      width: 14px;
      height: 14px;
    }

    /*::-webkit-scrollbar-track {
      background-color: #646464;
    }*/
    ::-webkit-scrollbar-track-piece {
      background-color: var(--mdc-theme-background);
    }
    ::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: rgba(var(--mdc-theme-on-surface),0.2);
      border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: rgba(var(--mdc-theme-on-surface),0.3);
    }
    ::-webkit-resizer {
      background-color: #666;
    }


/* Stepper */
ul.stepper li.active a .circle,
ul.stepper li.completed a .circle {
  color: var(--mdc-theme-on-primary);
  background-color: var(--mdc-theme-primary);
}

ul.stepper li.warning a .circle {
  background-color: #ff3547 !important
}

ul.stepper {
  counter-reset: section;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1em -1.5rem;
  padding: 0 1.5rem;
  padding: 1.5rem
}

ul.stepper li a {
  padding: 1.5rem;
  text-align: center
}

ul.stepper li a .circle {
  display: inline-block;
  color: rgba(var(--mdc-theme-on-surface), .38);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: rgba(0, 0, 0, .38);
  width: 1.75rem;
  height: 1.75rem;
  text-align: center;
  line-height: 1.7rem;
  margin-right: .5rem
}

ul.stepper li a .label {
  display: inline-block;
  color: rgba(var(--mdc-theme-on-surface), .38)
}

ul.stepper li.active a .label,
ul.stepper li.completed a .label {
  font-weight: 600;
  color: rgba(var(--mdc-theme-on-surface), .87)
}

.stepper-horizontal {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.stepper-horizontal li {
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative
}

.stepper-horizontal li a .label {
  margin-top: .63rem
}

.stepper-horizontal li:not(:last-child):after {
  content: "";
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: .5rem 0 0 0;
  height: 1px;
  background-color: rgba(0, 0, 0, .1)
}

.stepper-horizontal li:not(:first-child):before {
  content: "";
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: .5rem 0 0 0;
  height: 1px;
  background-color: rgba(0, 0, 0, .1)
}

.stepper-horizontal li:hover {
  background-color: rgba(var(--mdc-theme-on-surface), .06)
}

@media (max-width:47.9375rem) {
  .stepper-horizontal {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .stepper-horizontal li {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .stepper-horizontal li a .label {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    margin-top: .2rem
  }

  .stepper-horizontal li:not(:last-child):after {
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% - 40px);
    left: 2.19rem;
    top: 3.75rem
  }
}

.stepper-horizontal>li:not(:last-of-type) {
  margin-bottom: 0 !important
}

.stepper-vertical {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.stepper-vertical li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative
}

.stepper-vertical li a {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative
}

.stepper-vertical li a .circle {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1
}

.stepper-vertical li a .label {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  margin-top: .2rem
}

.stepper-vertical li.completed a .label {
  font-weight: 500
}

.stepper-vertical li .step-content {
  display: block;
  margin-top: 0;
  margin-left: 3.13rem;
  padding: .94rem
}

.stepper-vertical li .step-content p {
  font-size: .88rem
}

.stepper-vertical li:not(:last-child):after {
  content: "";
  position: absolute;
  width: 1px;
  height: calc(100% - 40px);
  left: 2.19rem;
  top: 3.44rem;
  background-color: rgba(0, 0, 0, .1)
}

/* Material Colors */

.md-color-red-50 {
  color: #ffebee !important;
}

.md-bg-red-50 {
  background-color: #ffebee !important;
}

.md-color-red-100 {
  color: #ffcdd2 !important;
}

.md-bg-red-100 {
  background-color: #ffcdd2 !important;
}

.md-color-red-200 {
  color: #ef9a9a !important;
}

.md-bg-red-200 {
  background-color: #ef9a9a !important;
}

.md-color-red-300 {
  color: #e57373 !important;
}

.md-bg-red-300 {
  background-color: #e57373 !important;
}

.md-color-red-400 {
  color: #ef5350 !important;
}

.md-bg-red-400 {
  background-color: #ef5350 !important;
}

.md-color-red-500 {
  color: #f44336 !important;
}

.md-bg-red-500 {
  background-color: #f44336 !important;
}

.md-color-red-600 {
  color: #e53935 !important;
}

.md-bg-red-600 {
  background-color: #e53935 !important;
}

.md-color-red-700 {
  color: #d32f2f !important;
}

.md-bg-red-700 {
  background-color: #d32f2f !important;
}

.md-color-red-800 {
  color: #c62828 !important;
}

.md-bg-red-800 {
  background-color: #c62828 !important;
}

.md-color-red-900 {
  color: #b71c1c !important;
}

.md-bg-red-900 {
  background-color: #b71c1c !important;
}

.md-color-red-A100 {
  color: #ff8a80 !important;
}

.md-bg-red-A100 {
  background-color: #ff8a80 !important;
}

.md-color-red-A200 {
  color: #ff5252 !important;
}

.md-bg-red-A200 {
  background-color: #ff5252 !important;
}

.md-color-red-A400 {
  color: #ff1744 !important;
}

.md-bg-red-A400 {
  background-color: #ff1744 !important;
}

.md-color-red-A700 {
  color: #d50000 !important;
}

.md-bg-red-A700 {
  background-color: #d50000 !important;
}

.md-color-pink-50 {
  color: #fce4ec !important;
}

.md-bg-pink-50 {
  background-color: #fce4ec !important;
}

.md-color-pink-100 {
  color: #f8bbd0 !important;
}

.md-bg-pink-100 {
  background-color: #f8bbd0 !important;
}

.md-color-pink-200 {
  color: #f48fb1 !important;
}

.md-bg-pink-200 {
  background-color: #f48fb1 !important;
}

.md-color-pink-300 {
  color: #f06292 !important;
}

.md-bg-pink-300 {
  background-color: #f06292 !important;
}

.md-color-pink-400 {
  color: #ec407a !important;
}

.md-bg-pink-400 {
  background-color: #ec407a !important;
}

.md-color-pink-500 {
  color: #e91e63 !important;
}

.md-bg-pink-500 {
  background-color: #e91e63 !important;
}

.md-color-pink-600 {
  color: #d81b60 !important;
}

.md-bg-pink-600 {
  background-color: #d81b60 !important;
}

.md-color-pink-700 {
  color: #c2185b !important
}

.md-bg-pink-700 {
  background-color: #c2185b !important
}

.md-color-pink-800 {
  color: #ad1457 !important;
}

.md-bg-pink-800 {
  background-color: #ad1457 !important;
}

.md-color-pink-900 {
  color: #880e4f !important;
}

.md-bg-pink-900 {
  background-color: #880e4f !important;
}

.md-color-pink-A100 {
  color: #ff80ab !important;
}

.md-bg-pink-A100 {
  background-color: #ff80ab !important;
}

.md-color-pink-A200 {
  color: #ff4081 !important;
}

.md-bg-pink-A200 {
  background-color: #ff4081 !important;
}

.md-color-pink-A400 {
  color: #f50057 !important;
}

.md-bg-pink-A400 {
  background-color: #f50057 !important;
}

.md-color-pink-A700 {
  color: #c51162 !important;
}

.md-bg-pink-A700 {
  background-color: #c51162 !important;
}

.md-color-purple-50 {
  color: #f3e5f5 !important;
}

.md-bg-purple-50 {
  background-color: #f3e5f5 !important;
}

.md-color-purple-100 {
  color: #e1bee7 !important;
}

.md-bg-purple-100 {
  background-color: #e1bee7 !important;
}

.md-color-purple-200 {
  color: #ce93d8 !important;
}

.md-bg-purple-200 {
  background-color: #ce93d8 !important;
}

.md-color-purple-300 {
  color: #ba68c8 !important;
}

.md-bg-purple-300 {
  background-color: #ba68c8 !important;
}

.md-color-purple-400 {
  color: #ab47bc !important;
}

.md-bg-purple-400 {
  background-color: #ab47bc !important;
}

.md-color-purple-500 {
  color: #9c27b0 !important;
}

.md-bg-purple-500 {
  background-color: #9c27b0 !important;
}

.md-color-purple-600 {
  color: #8e24aa !important;
}

.md-bg-purple-600 {
  background-color: #8e24aa !important;
}

.md-color-purple-700 {
  color: #7b1fa2 !important;
}

.md-bg-purple-700 {
  background-color: #7b1fa2 !important;
}

.md-color-purple-800 {
  color: #6a1b9a !important;
}

.md-bg-purple-800 {
  background-color: #6a1b9a !important;
}

.md-color-purple-900 {
  color: #4a148c !important;
}

.md-bg-purple-900 {
  background-color: #4a148c !important;
}

.md-color-purple-A100 {
  color: #ea80fc !important;
}

.md-bg-purple-A100 {
  background-color: #ea80fc !important;
}

.md-color-purple-A200 {
  color: #e040fb !important;
}

.md-bg-purple-A200 {
  background-color: #e040fb !important;
}

.md-color-purple-A400 {
  color: #d500f9 !important;
}

.md-bg-purple-A400 {
  background-color: #d500f9 !important;
}

.md-color-purple-A700 {
  color: #aa00ff !important;
}

.md-bg-purple-A700 {
  background-color: #aa00ff !important;
}

.md-color-deep-purple-50 {
  color: #ede7f6 !important;
}

.md-bg-deep-purple-50 {
  background-color: #ede7f6 !important;
}

.md-color-deep-purple-100 {
  color: #d1c4e9 !important;
}

.md-bg-deep-purple-100 {
  background-color: #d1c4e9 !important;
}

.md-color-deep-purple-200 {
  color: #b39ddb !important;
}

.md-bg-deep-purple-200 {
  background-color: #b39ddb !important;
}

.md-color-deep-purple-300 {
  color: #9575cd !important;
}

.md-bg-deep-purple-300 {
  background-color: #9575cd !important;
}

.md-color-deep-purple-400 {
  color: #7e57c2 !important;
}

.md-bg-deep-purple-400 {
  background-color: #7e57c2 !important;
}

.md-color-deep-purple-500 {
  color: #673ab7 !important;
}

.md-bg-deep-purple-500 {
  background-color: #673ab7 !important;
}

.md-color-deep-purple-600 {
  color: #5e35b1 !important;
}

.md-bg-deep-purple-600 {
  background-color: #5e35b1 !important;
}

.md-color-deep-purple-700 {
  color: #512da8 !important;
}

.md-bg-deep-purple-700 {
  background-color: #512da8 !important;
}

.md-color-deep-purple-800 {
  color: #4527a0 !important;
}

.md-bg-deep-purple-800 {
  background-color: #4527a0 !important;
}

.md-color-deep-purple-900 {
  color: #311b92 !important;
}

.md-bg-deep-purple-900 {
  background-color: #311b92 !important;
}

.md-color-deep-purple-A100 {
  color: #b388ff !important;
}

.md-bg-deep-purple-A100 {
  background-color: #b388ff !important;
}

.md-color-deep-purple-A200 {
  color: #7c4dff !important;
}

.md-bg-deep-purple-A200 {
  background-color: #7c4dff !important;
}

.md-color-deep-purple-A400 {
  color: #651fff !important;
}

.md-bg-deep-purple-A400 {
  background-color: #651fff !important;
}

.md-color-deep-purple-A700 {
  color: #6200ea !important;
}

.md-bg-deep-purple-A700 {
  background-color: #6200ea !important;
}

.md-color-indigo-50 {
  color: #e8eaf6 !important;
}

.md-bg-indigo-50 {
  background-color: #e8eaf6 !important;
}

.md-color-indigo-100 {
  color: #c5cae9 !important;
}

.md-bg-indigo-100 {
  background-color: #c5cae9 !important;
}

.md-color-indigo-200 {
  color: #9fa8da !important;
}

.md-bg-indigo-200 {
  background-color: #9fa8da !important;
}

.md-color-indigo-300 {
  color: #7986cb !important;
}

.md-bg-indigo-300 {
  background-color: #7986cb !important;
}

.md-color-indigo-400 {
  color: #5c6bc0 !important;
}

.md-bg-indigo-400 {
  background-color: #5c6bc0 !important;
}

.md-color-indigo-500 {
  color: #3f51b5 !important;
}

.md-bg-indigo-500 {
  background-color: #3f51b5 !important;
}

.md-color-indigo-600 {
  color: #3949ab !important;
}

.md-bg-indigo-600 {
  background-color: #3949ab !important;
}

.md-color-indigo-700 {
  color: #303f9f !important;
}

.md-bg-indigo-700 {
  background-color: #303f9f !important;
}

.md-color-indigo-800 {
  color: #283593 !important;
}

.md-bg-indigo-800 {
  background-color: #283593 !important;
}

.md-color-indigo-900 {
  color: #1a237e !important;
}

.md-bg-indigo-900 {
  background-color: #1a237e !important;
}

.md-color-indigo-A100 {
  color: #8c9eff !important;
}

.md-bg-indigo-A100 {
  background-color: #8c9eff !important;
}

.md-color-indigo-A200 {
  color: #536dfe !important;
}

.md-bg-indigo-A200 {
  background-color: #536dfe !important;
}

.md-color-indigo-A400 {
  color: #3d5afe !important;
}

.md-bg-indigo-A400 {
  background-color: #3d5afe !important;
}

.md-color-indigo-A700 {
  color: #304ffe !important;
}

.md-bg-indigo-A700 {
  background-color: #304ffe !important;
}

.md-color-blue-50 {
  color: #e3f2fd !important;
}

.md-bg-blue-50 {
  background-color: #e3f2fd !important;
}

.md-color-blue-100 {
  color: #bbdefb !important;
}

.md-bg-blue-100 {
  background-color: #bbdefb !important;
}

.md-color-blue-200 {
  color: #90caf9 !important;
}

.md-bg-blue-200 {
  background-color: #90caf9 !important;
}

.md-color-blue-300 {
  color: #64b5f6 !important;
}

.md-bg-blue-300 {
  background-color: #64b5f6 !important;
}

.md-color-blue-400 {
  color: #42a5f5 !important;
}

.md-bg-blue-400 {
  background-color: #42a5f5 !important;
}

.md-color-blue-500 {
  color: #2196f3 !important;
}

.md-bg-blue-500 {
  background-color: #2196f3 !important;
}

.md-color-blue-600 {
  color: #1e88e5 !important;
}

.md-bg-blue-600 {
  background-color: #1e88e5 !important;
}

.md-color-blue-700 {
  color: #1976d2 !important;
}

.md-bg-blue-700 {
  background-color: #1976d2 !important;
}

.md-color-blue-800 {
  color: #1565c0 !important;
}

.md-bg-blue-800 {
  background-color: #1565c0 !important;
}

.md-color-blue-900 {
  color: #0d47a1 !important;
}

.md-bg-blue-900 {
  background-color: #0d47a1 !important;
}

.md-color-blue-A100 {
  color: #82b1ff !important;
}

.md-bg-blue-A100 {
  background-color: #82b1ff !important;
}

.md-color-blue-A200 {
  color: #448aff !important;
}

.md-bg-blue-A200 {
  background-color: #448aff !important;
}

.md-color-blue-A400 {
  color: #2979ff !important;
}

.md-bg-blue-A400 {
  background-color: #2979ff !important;
}

.md-color-blue-A700 {
  color: #2962ff !important;
}

.md-bg-blue-A700 {
  background-color: #2962ff !important;
}

.md-color-light-blue-50 {
  color: #e1f5fe !important;
}

.md-bg-light-blue-50 {
  background-color: #e1f5fe !important;
}

.md-color-light-blue-100 {
  color: #b3e5fc !important;
}

.md-bg-light-blue-100 {
  background-color: #b3e5fc !important;
}

.md-color-light-blue-200 {
  color: #81d4fa !important;
}

.md-bg-light-blue-200 {
  background-color: #81d4fa !important;
}

.md-color-light-blue-300 {
  color: #4fc3f7 !important;
}

.md-bg-light-blue-300 {
  background-color: #4fc3f7 !important;
}

.md-color-light-blue-400 {
  color: #29b6f6 !important;
}

.md-bg-light-blue-400 {
  background-color: #29b6f6 !important;
}

.md-color-light-blue-500 {
  color: #03a9f4 !important;
}

.md-bg-light-blue-500 {
  background-color: #03a9f4 !important;
}

.md-color-light-blue-600 {
  color: #039be5 !important;
}

.md-bg-light-blue-600 {
  background-color: #039be5 !important;
}

.md-color-light-blue-700 {
  color: #0288d1 !important;
}

.md-bg-light-blue-700 {
  background-color: #0288d1 !important;
}

.md-color-light-blue-800 {
  color: #0277bd !important;
}

.md-bg-light-blue-800 {
  background-color: #0277bd !important;
}

.md-color-light-blue-900 {
  color: #01579b !important;
}

.md-bg-light-blue-900 {
  background-color: #01579b !important;
}

.md-color-light-blue-A100 {
  color: #80d8ff !important;
}

.md-bg-light-blue-A100 {
  background-color: #80d8ff !important;
}

.md-color-light-blue-A200 {
  color: #40c4ff !important;
}

.md-bg-light-blue-A200 {
  background-color: #40c4ff !important;
}

.md-color-light-blue-A400 {
  color: #00b0ff !important;
}

.md-bg-light-blue-A400 {
  background-color: #00b0ff !important;
}

.md-color-light-blue-A700 {
  color: #0091ea !important;
}

.md-bg-light-blue-A700 {
  background-color: #0091ea !important;
}

.md-color-cyan-50 {
  color: #e0f7fa !important;
}

.md-bg-cyan-50 {
  background-color: #e0f7fa !important;
}

.md-color-cyan-100 {
  color: #b2ebf2 !important;
}

.md-bg-cyan-100 {
  background-color: #b2ebf2 !important;
}

.md-color-cyan-200 {
  color: #80deea !important;
}

.md-bg-cyan-200 {
  background-color: #80deea !important;
}

.md-color-cyan-300 {
  color: #4dd0e1 !important;
}

.md-bg-cyan-300 {
  background-color: #4dd0e1 !important;
}

.md-color-cyan-400 {
  color: #26c6da !important;
}

.md-bg-cyan-400 {
  background-color: #26c6da !important;
}

.md-color-cyan-500 {
  color: #00bcd4 !important;
}

.md-bg-cyan-500 {
  background-color: #00bcd4 !important;
}

.md-color-cyan-600 {
  color: #00acc1 !important;
}

.md-bg-cyan-600 {
  background-color: #00acc1 !important;
}

.md-color-cyan-700 {
  color: #0097a7 !important;
}

.md-bg-cyan-700 {
  background-color: #0097a7 !important;
}

.md-color-cyan-800 {
  color: #00838f !important;
}

.md-bg-cyan-800 {
  background-color: #00838f !important;
}

.md-color-cyan-900 {
  color: #006064 !important;
}

.md-bg-cyan-900 {
  background-color: #006064 !important;
}

.md-color-cyan-A100 {
  color: #84ffff !important;
}

.md-bg-cyan-A100 {
  background-color: #84ffff !important;
}

.md-color-cyan-A200 {
  color: #18ffff !important;
}

.md-bg-cyan-A200 {
  background-color: #18ffff !important;
}

.md-color-cyan-A400 {
  color: #00e5ff !important;
}

.md-bg-cyan-A400 {
  background-color: #00e5ff !important;
}

.md-color-cyan-A700 {
  color: #00b8d4 !important;
}

.md-bg-cyan-A700 {
  background-color: #00b8d4 !important;
}

.md-color-teal-50 {
  color: #e0f2f1 !important;
}

.md-bg-teal-50 {
  background-color: #e0f2f1 !important;
}

.md-color-teal-100 {
  color: #b2dfdb !important;
}

.md-bg-teal-100 {
  background-color: #b2dfdb !important;
}

.md-color-teal-200 {
  color: #80cbc4 !important;
}

.md-bg-teal-200 {
  background-color: #80cbc4 !important;
}

.md-color-teal-300 {
  color: #4db6ac !important;
}

.md-bg-teal-300 {
  background-color: #4db6ac !important;
}

.md-color-teal-400 {
  color: #26a69a !important;
}

.md-bg-teal-400 {
  background-color: #26a69a !important;
}

.md-color-teal-500 {
  color: #009688 !important;
}

.md-bg-teal-500 {
  background-color: #009688 !important;
}

.md-color-teal-600 {
  color: #00897b !important;
}

.md-bg-teal-600 {
  background-color: #00897b !important;
}

.md-color-teal-700 {
  color: #00796b !important;
}

.md-bg-teal-700 {
  background-color: #00796b !important;
}

.md-color-teal-800 {
  color: #00695c !important;
}

.md-bg-teal-800 {
  background-color: #00695c !important;
}

.md-color-teal-900 {
  color: #004d40 !important;
}

.md-bg-teal-900 {
  background-color: #004d40 !important;
}

.md-color-teal-A100 {
  color: #a7ffeb !important;
}

.md-bg-teal-A100 {
  background-color: #a7ffeb !important;
}

.md-color-teal-A200 {
  color: #64ffda !important;
}

.md-bg-teal-A200 {
  background-color: #64ffda !important;
}

.md-color-teal-A400 {
  color: #1de9b6 !important;
}

.md-bg-teal-A400 {
  background-color: #1de9b6 !important;
}

.md-color-teal-A700 {
  color: #00bfa5 !important;
}

.md-bg-teal-A700 {
  background-color: #00bfa5 !important;
}

.md-color-green-50 {
  color: #e8f5e9 !important;
}

.md-bg-green-50 {
  background-color: #e8f5e9 !important;
}

.md-color-green-100 {
  color: #c8e6c9 !important;
}

.md-bg-green-100 {
  background-color: #c8e6c9 !important;
}

.md-color-green-200 {
  color: #a5d6a7 !important;
}

.md-bg-green-200 {
  background-color: #a5d6a7 !important;
}

.md-color-green-300 {
  color: #81c784 !important;
}

.md-bg-green-300 {
  background-color: #81c784 !important;
}

.md-color-green-400 {
  color: #66bb6a !important;
}

.md-bg-green-400 {
  background-color: #66bb6a !important;
}

.md-color-green-500 {
  color: #4caf50 !important;
}

.md-bg-green-500 {
  background-color: #4caf50 !important;
}

.md-color-green-600 {
  color: #43a047 !important;
}

.md-bg-green-600 {
  background-color: #43a047 !important;
}

.md-color-green-700 {
  color: #388e3c !important;
}

.md-bg-green-700 {
  background-color: #388e3c !important;
}

.md-color-green-800 {
  color: #2e7d32 !important;
}

.md-bg-green-800 {
  background-color: #2e7d32 !important;
}

.md-color-green-900 {
  color: #1b5e20 !important;
}

.md-bg-green-900 {
  background-color: #1b5e20 !important;
}

.md-color-green-A100 {
  color: #b9f6ca !important;
}

.md-bg-green-A100 {
  background-color: #b9f6ca !important;
}

.md-color-green-A200 {
  color: #69f0ae !important;
}

.md-bg-green-A200 {
  background-color: #69f0ae !important;
}

.md-color-green-A400 {
  color: #00e676 !important;
}

.md-bg-green-A400 {
  background-color: #00e676 !important;
}

.md-color-green-A700 {
  color: #00c853 !important;
}

.md-bg-green-A700 {
  background-color: #00c853 !important;
}

.md-color-light-green-50 {
  color: #f1f8e9 !important;
}

.md-bg-light-green-50 {
  background-color: #f1f8e9 !important;
}

.md-color-light-green-100 {
  color: #dcedc8 !important;
}

.md-bg-light-green-100 {
  background-color: #dcedc8 !important;
}

.md-color-light-green-200 {
  color: #c5e1a5 !important;
}

.md-bg-light-green-200 {
  background-color: #c5e1a5 !important;
}

.md-color-light-green-300 {
  color: #aed581 !important;
}

.md-bg-light-green-300 {
  background-color: #aed581 !important;
}

.md-color-light-green-400 {
  color: #9ccc65 !important;
}

.md-bg-light-green-400 {
  background-color: #9ccc65 !important;
}

.md-color-light-green-500 {
  color: #8bc34a !important;
}

.md-bg-light-green-500 {
  background-color: #8bc34a !important;
}

.md-color-light-green-600 {
  color: #7cb342 !important;
}

.md-bg-light-green-600 {
  background-color: #7cb342 !important;
}

.md-color-light-green-700 {
  color: #689f38 !important;
}

.md-bg-light-green-700 {
  background-color: #689f38 !important;
}

.md-color-light-green-800 {
  color: #558b2f !important;
}

.md-bg-light-green-800 {
  background-color: #558b2f !important;
}

.md-color-light-green-900 {
  color: #33691e !important;
}

.md-bg-light-green-900 {
  background-color: #33691e !important;
}

.md-color-light-green-A100 {
  color: #ccff90 !important;
}

.md-bg-light-green-A100 {
  background-color: #ccff90 !important;
}

.md-color-light-green-A200 {
  color: #b2ff59 !important;
}

.md-bg-light-green-A200 {
  background-color: #b2ff59 !important;
}

.md-color-light-green-A400 {
  color: #76ff03 !important;
}

.md-bg-light-green-A400 {
  background-color: #76ff03 !important;
}

.md-color-light-green-A700 {
  color: #64dd17 !important;
}

.md-bg-light-green-A700 {
  background-color: #64dd17 !important;
}

.md-color-lime-50 {
  color: #f9fbe7 !important;
}

.md-bg-lime-50 {
  background-color: #f9fbe7 !important;
}

.md-color-lime-100 {
  color: #f0f4c3 !important;
}

.md-bg-lime-100 {
  background-color: #f0f4c3 !important;
}

.md-color-lime-200 {
  color: #e6ee9c !important;
}

.md-bg-lime-200 {
  background-color: #e6ee9c !important;
}

.md-color-lime-300 {
  color: #dce775 !important;
}

.md-bg-lime-300 {
  background-color: #dce775 !important;
}

.md-color-lime-400 {
  color: #d4e157 !important;
}

.md-bg-lime-400 {
  background-color: #d4e157 !important;
}

.md-color-lime-500 {
  color: #cddc39 !important;
}

.md-bg-lime-500 {
  background-color: #cddc39 !important;
}

.md-color-lime-600 {
  color: #c0ca33 !important;
}

.md-bg-lime-600 {
  background-color: #c0ca33 !important;
}

.md-color-lime-700 {
  color: #afb42b !important;
}

.md-bg-lime-700 {
  background-color: #afb42b !important;
}

.md-color-lime-800 {
  color: #9e9d24 !important;
}

.md-bg-lime-800 {
  background-color: #9e9d24 !important;
}

.md-color-lime-900 {
  color: #827717 !important;
}

.md-bg-lime-900 {
  background-color: #827717 !important;
}

.md-color-lime-A100 {
  color: #f4ff81 !important;
}

.md-bg-lime-A100 {
  background-color: #f4ff81 !important;
}

.md-color-lime-A200 {
  color: #eeff41 !important;
}

.md-bg-lime-A200 {
  background-color: #eeff41 !important;
}

.md-color-lime-A400 {
  color: #c6ff00 !important;
}

.md-bg-lime-A400 {
  background-color: #c6ff00 !important;
}

.md-color-lime-A700 {
  color: #aeea00 !important;
}

.md-bg-lime-A700 {
  background-color: #aeea00 !important;
}

.md-color-yellow-50 {
  color: #fffde7 !important;
}

.md-bg-yellow-50 {
  background-color: #fffde7 !important;
}

.md-color-yellow-100 {
  color: #fff9c4 !important;
}

.md-bg-yellow-100 {
  background-color: #fff9c4 !important;
}

.md-color-yellow-200 {
  color: #fff59d !important;
}

.md-bg-yellow-200 {
  background-color: #fff59d !important;
}

.md-color-yellow-300 {
  color: #fff176 !important;
}

.md-bg-yellow-300 {
  background-color: #fff176 !important;
}

.md-color-yellow-400 {
  color: #ffee58 !important;
}

.md-bg-yellow-400 {
  background-color: #ffee58 !important;
}

.md-color-yellow-500 {
  color: #ffeb3b !important;
}

.md-bg-yellow-500 {
  background-color: #ffeb3b !important;
}

.md-color-yellow-600 {
  color: #fdd835 !important;
}

.md-bg-yellow-600 {
  background-color: #fdd835 !important;
}

.md-color-yellow-700 {
  color: #fbc02d !important;
}

.md-bg-yellow-700 {
  background-color: #fbc02d !important;
}

.md-color-yellow-800 {
  color: #f9a825 !important;
}

.md-bg-yellow-800 {
  background-color: #f9a825 !important;
}

.md-color-yellow-900 {
  color: #f57f17 !important;
}

.md-bg-yellow-900 {
  background-color: #f57f17 !important;
}

.md-color-yellow-A100 {
  color: #ffff8d !important;
}

.md-bg-yellow-A100 {
  background-color: #ffff8d !important;
}

.md-color-yellow-A200 {
  color: #ffff00 !important;
}

.md-bg-yellow-A200 {
  background-color: #ffff00 !important;
}

.md-color-yellow-A400 {
  color: #ffea00 !important;
}

.md-bg-yellow-A400 {
  background-color: #ffea00 !important;
}

.md-color-yellow-A700 {
  color: #ffd600 !important;
}

.md-bg-yellow-A700 {
  background-color: #ffd600 !important;
}

.md-color-amber-50 {
  color: #fff8e1 !important;
}

.md-bg-amber-50 {
  background-color: #fff8e1 !important;
}

.md-color-amber-100 {
  color: #ffecb3 !important;
}

.md-bg-amber-100 {
  background-color: #ffecb3 !important;
}

.md-color-amber-200 {
  color: #ffe082 !important;
}

.md-bg-amber-200 {
  background-color: #ffe082 !important;
}

.md-color-amber-300 {
  color: #ffd54f !important;
}

.md-bg-amber-300 {
  background-color: #ffd54f !important;
}

.md-color-amber-400 {
  color: #ffca28 !important;
}

.md-bg-amber-400 {
  background-color: #ffca28 !important;
}

.md-color-amber-500 {
  color: #ffc107 !important;
}

.md-bg-amber-500 {
  background-color: #ffc107 !important;
}

.md-color-amber-600 {
  color: #ffb300 !important;
}

.md-bg-amber-600 {
  background-color: #ffb300 !important;
}

.md-color-amber-700 {
  color: #ffa000 !important;
}

.md-bg-amber-700 {
  background-color: #ffa000 !important;
}

.md-color-amber-800 {
  color: #ff8f00 !important;
}

.md-bg-amber-800 {
  background-color: #ff8f00 !important;
}

.md-color-amber-900 {
  color: #ff6f00 !important;
}

.md-bg-amber-900 {
  background-color: #ff6f00 !important;
}

.md-color-amber-A100 {
  color: #ffe57f !important;
}

.md-bg-amber-A100 {
  background-color: #ffe57f !important;
}

.md-color-amber-A200 {
  color: #ffd740 !important;
}

.md-bg-amber-A200 {
  background-color: #ffd740 !important;
}

.md-color-amber-A400 {
  color: #ffc400 !important;
}

.md-bg-amber-A400 {
  background-color: #ffc400 !important;
}

.md-color-amber-A700 {
  color: #ffab00 !important;
}

.md-bg-amber-A700 {
  background-color: #ffab00 !important;
}

.md-color-orange-50 {
  color: #fff3e0 !important;
}

.md-bg-orange-50 {
  background-color: #fff3e0 !important;
}

.md-color-orange-100 {
  color: #ffe0b2 !important;
}

.md-bg-orange-100 {
  background-color: #ffe0b2 !important;
}

.md-color-orange-200 {
  color: #ffcc80 !important;
}

.md-bg-orange-200 {
  background-color: #ffcc80 !important;
}

.md-color-orange-300 {
  color: #ffb74d !important;
}

.md-bg-orange-300 {
  background-color: #ffb74d !important;
}

.md-color-orange-400 {
  color: #ffa726 !important;
}

.md-bg-orange-400 {
  background-color: #ffa726 !important;
}

.md-color-orange-500 {
  color: #ff9800 !important;
}

.md-bg-orange-500 {
  background-color: #ff9800 !important;
}

.md-color-orange-600 {
  color: #fb8c00 !important;
}

.md-bg-orange-600 {
  background-color: #fb8c00 !important;
}

.md-color-orange-700 {
  color: #f57c00 !important;
}

.md-bg-orange-700 {
  background-color: #f57c00 !important;
}

.md-color-orange-800 {
  color: #ef6c00 !important;
}

.md-bg-orange-800 {
  background-color: #ef6c00 !important;
}

.md-color-orange-900 {
  color: #e65100 !important;
}

.md-bg-orange-900 {
  background-color: #e65100 !important;
}

.md-color-orange-A100 {
  color: #ffd180 !important;
}

.md-bg-orange-A100 {
  background-color: #ffd180 !important;
}

.md-color-orange-A200 {
  color: #ffab40 !important;
}

.md-bg-orange-A200 {
  background-color: #ffab40 !important;
}

.md-color-orange-A400 {
  color: #ff9100 !important;
}

.md-bg-orange-A400 {
  background-color: #ff9100 !important;
}

.md-color-orange-A700 {
  color: #ff6d00 !important;
}

.md-bg-orange-A700 {
  background-color: #ff6d00 !important;
}

.md-color-deep-orange-50 {
  color: #fbe9e7 !important;
}

.md-bg-deep-orange-50 {
  background-color: #fbe9e7 !important;
}

.md-color-deep-orange-100 {
  color: #ffccbc !important;
}

.md-bg-deep-orange-100 {
  background-color: #ffccbc !important;
}

.md-color-deep-orange-200 {
  color: #ffab91 !important;
}

.md-bg-deep-orange-200 {
  background-color: #ffab91 !important;
}

.md-color-deep-orange-300 {
  color: #ff8a65 !important;
}

.md-bg-deep-orange-300 {
  background-color: #ff8a65 !important;
}

.md-color-deep-orange-400 {
  color: #ff7043 !important;
}

.md-bg-deep-orange-400 {
  background-color: #ff7043 !important;
}

.md-color-deep-orange-500 {
  color: #ff5722 !important;
}

.md-bg-deep-orange-500 {
  background-color: #ff5722 !important;
}

.md-color-deep-orange-600 {
  color: #f4511e !important;
}

.md-bg-deep-orange-600 {
  background-color: #f4511e !important;
}

.md-color-deep-orange-700 {
  color: #e64a19 !important;
}

.md-bg-deep-orange-700 {
  background-color: #e64a19 !important;
}

.md-color-deep-orange-800 {
  color: #d84315 !important;
}

.md-bg-deep-orange-800 {
  background-color: #d84315 !important;
}

.md-color-deep-orange-900 {
  color: #bf360c !important;
}

.md-bg-deep-orange-900 {
  background-color: #bf360c !important;
}

.md-color-deep-orange-A100 {
  color: #ff9e80 !important;
}

.md-bg-deep-orange-A100 {
  background-color: #ff9e80 !important;
}

.md-color-deep-orange-A200 {
  color: #ff6e40 !important;
}

.md-bg-deep-orange-A200 {
  background-color: #ff6e40 !important;
}

.md-color-deep-orange-A400 {
  color: #ff3d00 !important;
}

.md-bg-deep-orange-A400 {
  background-color: #ff3d00 !important;
}

.md-color-deep-orange-A700 {
  color: #dd2c00 !important;
}

.md-bg-deep-orange-A700 {
  background-color: #dd2c00 !important;
}

.md-color-brown-50 {
  color: #efebe9 !important;
}

.md-bg-brown-50 {
  background-color: #efebe9 !important;
}

.md-color-brown-100 {
  color: #d7ccc8 !important;
}

.md-bg-brown-100 {
  background-color: #d7ccc8 !important;
}

.md-color-brown-200 {
  color: #bcaaa4 !important;
}

.md-bg-brown-200 {
  background-color: #bcaaa4 !important;
}

.md-color-brown-300 {
  color: #a1887f !important;
}

.md-bg-brown-300 {
  background-color: #a1887f !important;
}

.md-color-brown-400 {
  color: #8d6e63 !important;
}

.md-bg-brown-400 {
  background-color: #8d6e63 !important;
}

.md-color-brown-500 {
  color: #795548 !important;
}

.md-bg-brown-500 {
  background-color: #795548 !important;
}

.md-color-brown-600 {
  color: #6d4c41 !important;
}

.md-bg-brown-600 {
  background-color: #6d4c41 !important;
}

.md-color-brown-700 {
  color: #5d4037 !important;
}

.md-bg-brown-700 {
  background-color: #5d4037 !important;
}

.md-color-brown-800 {
  color: #4e342e !important;
}

.md-bg-brown-800 {
  background-color: #4e342e !important;
}

.md-color-brown-900 {
  color: #3e2723 !important;
}

.md-bg-brown-900 {
  background-color: #3e2723 !important;
}

.md-color-grey-50 {
  color: #fafafa !important;
}

.md-bg-grey-50 {
  background-color: #fafafa !important;
}

.md-color-grey-100 {
  color: #f5f5f5 !important;
}

.md-bg-grey-100 {
  background-color: #f5f5f5 !important;
}

.md-color-grey-200 {
  color: #eeeeee !important;
}

.md-bg-grey-200 {
  background-color: #eeeeee !important;
}

.md-color-grey-300 {
  color: #e0e0e0 !important;
}

.md-bg-grey-300 {
  background-color: #e0e0e0 !important;
}

.md-color-grey-400 {
  color: #bdbdbd !important;
}

.md-bg-grey-400 {
  background-color: #bdbdbd !important;
}

.md-color-grey-500 {
  color: #9e9e9e !important;
}

.md-bg-grey-500 {
  background-color: #9e9e9e !important;
}

.md-color-grey-600 {
  color: #757575 !important;
}

.md-bg-grey-600 {
  background-color: #757575 !important;
}

.md-color-grey-700 {
  color: #616161 !important;
}

.md-bg-grey-700 {
  background-color: #616161 !important;
}

.md-color-grey-800 {
  color: #424242 !important;
}

.md-bg-grey-800 {
  background-color: #424242 !important;
}

.md-color-grey-900 {
  color: #212121 !important;
}

.md-bg-grey-900 {
  background-color: #212121 !important;
}

.md-color-blue-grey-50 {
  color: #eceff1 !important;
}

.md-bg-blue-grey-50 {
  background-color: #eceff1 !important;
}

.md-color-blue-grey-100 {
  color: #cfd8dc !important;
}

.md-bg-blue-grey-100 {
  background-color: #cfd8dc !important;
}

.md-color-blue-grey-200 {
  color: #b0bec5 !important;
}

.md-bg-blue-grey-200 {
  background-color: #b0bec5 !important;
}

.md-color-blue-grey-300 {
  color: #90a4ae !important;
}

.md-bg-blue-grey-300 {
  background-color: #90a4ae !important;
}

.md-color-blue-grey-400 {
  color: #78909c !important;
}

.md-bg-blue-grey-400 {
  background-color: #78909c !important;
}

.md-color-blue-grey-500 {
  color: #607d8b !important;
}

.md-bg-blue-grey-500 {
  background-color: #607d8b !important;
}

.md-color-blue-grey-600 {
  color: #546e7a !important;
}

.md-bg-blue-grey-600 {
  background-color: #546e7a !important;
}

.md-color-blue-grey-700 {
  color: #455a64 !important;
}

.md-bg-blue-grey-700 {
  background-color: #455a64 !important;
}

.md-color-blue-grey-800 {
  color: #37474f !important;
}

.md-bg-blue-grey-800 {
  background-color: #37474f !important;
}

.md-color-blue-grey-900 {
  color: #263238 !important;
}

.md-bg-blue-grey-900 {
  background-color: #263238 !important;
}

.md-color-white {
  color: #fff !important;
}

.md-bg-white {
  background: #fff !important;
}

.rgba-red-slight,
.rgba-red-slight:after {
  background-color: rgba(244, 67, 54, 0.1);
}

.rgba-red-light,
.rgba-red-light:after {
  background-color: rgba(244, 67, 54, 0.3);
}

.rgba-red-strong,
.rgba-red-strong:after {
  background-color: rgba(244, 67, 54, 0.7);
}

.rgba-pink-slight,
.rgba-pink-slight:after {
  background-color: rgba(233, 30, 99, 0.1);
}

.rgba-pink-light,
.rgba-pink-light:after {
  background-color: rgba(233, 30, 99, 0.3);
}

.rgba-pink-strong,
.rgba-pink-strong:after {
  background-color: rgba(233, 30, 99, 0.7);
}

.rgba-purple-slight,
.rgba-purple-slight:after {
  background-color: rgba(156, 39, 176, 0.1);
}

.rgba-purple-light,
.rgba-purple-light:after {
  background-color: rgba(156, 39, 176, 0.3);
}

.rgba-purple-strong,
.rgba-purple-strong:after {
  background-color: rgba(156, 39, 176, 0.7);
}

.rgba-deep-purple-slight,
.rgba-deep-purple-slight:after {
  background-color: rgba(103, 58, 183, 0.1);
}

.rgba-deep-purple-light,
.rgba-deep-purple-light:after {
  background-color: rgba(103, 58, 183, 0.3);
}

.rgba-deep-purple-strong,
.rgba-deep-purple-strong:after {
  background-color: rgba(103, 58, 183, 0.7);
}

.rgba-indigo-slight,
.rgba-indigo-slight:after {
  background-color: rgba(63, 81, 181, 0.1);
}

.rgba-indigo-light,
.rgba-indigo-light:after {
  background-color: rgba(63, 81, 181, 0.3);
}

.rgba-indigo-strong,
.rgba-indigo-strong:after {
  background-color: rgba(63, 81, 181, 0.7);
}

.rgba-blue-slight,
.rgba-blue-slight:after {
  background-color: rgba(33, 150, 243, 0.1);
}

.rgba-blue-light,
.rgba-blue-light:after {
  background-color: rgba(33, 150, 243, 0.3);
}

.rgba-blue-strong,
.rgba-blue-strong:after {
  background-color: rgba(33, 150, 243, 0.7);
}

.rgba-light-blue-slight,
.rgba-light-blue-slight:after {
  background-color: rgba(3, 169, 244, 0.1);
}

.rgba-light-blue-light,
.rgba-light-blue-light:after {
  background-color: rgba(3, 169, 244, 0.3);
}

.rgba-light-blue-strong,
.rgba-light-blue-strong:after {
  background-color: rgba(3, 169, 244, 0.7);
}

.rgba-cyan-slight,
.rgba-cyan-slight:after {
  background-color: rgba(0, 188, 212, 0.1);
}

.rgba-cyan-light,
.rgba-cyan-light:after {
  background-color: rgba(0, 188, 212, 0.3);
}

.rgba-cyan-strong,
.rgba-cyan-strong:after {
  background-color: rgba(0, 188, 212, 0.7);
}

.rgba-teal-slight,
.rgba-teal-slight:after {
  background-color: rgba(0, 150, 136, 0.1);
}

.rgba-teal-light,
.rgba-teal-light:after {
  background-color: rgba(0, 150, 136, 0.3);
}

.rgba-teal-strong,
.rgba-teal-strong:after {
  background-color: rgba(0, 150, 136, 0.7);
}

.rgba-green-slight,
.rgba-green-slight:after {
  background-color: rgba(76, 175, 80, 0.1);
}

.rgba-green-light,
.rgba-green-light:after {
  background-color: rgba(76, 175, 80, 0.3);
}

.rgba-green-strong,
.rgba-green-strong:after {
  background-color: rgba(76, 175, 80, 0.7);
}

.rgba-light-green-slight,
.rgba-light-green-slight:after {
  background-color: rgba(139, 195, 74, 0.1);
}

.rgba-light-green-light,
.rgba-light-green-light:after {
  background-color: rgba(139, 195, 74, 0.3);
}

.rgba-light-green-strong,
.rgba-light-green-strong:after {
  background-color: rgba(139, 195, 74, 0.7);
}

.rgba-lime-slight,
.rgba-lime-slight:after {
  background-color: rgba(205, 220, 57, 0.1);
}

.rgba-lime-light,
.rgba-lime-light:after {
  background-color: rgba(205, 220, 57, 0.3);
}

.rgba-lime-strong,
.rgba-lime-strong:after {
  background-color: rgba(205, 220, 57, 0.7);
}

.rgba-yellow-slight,
.rgba-yellow-slight:after {
  background-color: rgba(255, 235, 59, 0.1);
}

.rgba-yellow-light,
.rgba-yellow-light:after {
  background-color: rgba(255, 235, 59, 0.3);
}

.rgba-yellow-strong,
.rgba-yellow-strong:after {
  background-color: rgba(255, 235, 59, 0.7);
}

.rgba-amber-slight,
.rgba-amber-slight:after {
  background-color: rgba(255, 193, 7, 0.1);
}

.rgba-amber-light,
.rgba-amber-light:after {
  background-color: rgba(255, 193, 7, 0.3);
}

.rgba-amber-strong,
.rgba-amber-strong:after {
  background-color: rgba(255, 193, 7, 0.7);
}

.rgba-orange-slight,
.rgba-orange-slight:after {
  background-color: rgba(255, 152, 0, 0.1);
}

.rgba-orange-light,
.rgba-orange-light:after {
  background-color: rgba(255, 152, 0, 0.3);
}

.rgba-orange-strong,
.rgba-orange-strong:after {
  background-color: rgba(255, 152, 0, 0.7);
}

.rgba-deep-orange-slight,
.rgba-deep-orange-slight:after {
  background-color: rgba(255, 87, 34, 0.1);
}

.rgba-deep-orange-light,
.rgba-deep-orange-light:after {
  background-color: rgba(255, 87, 34, 0.3);
}

.rgba-deep-orange-strong,
.rgba-deep-orange-strong:after {
  background-color: rgba(255, 87, 34, 0.7);
}

.rgba-brown-slight,
.rgba-brown-slight:after {
  background-color: rgba(121, 85, 72, 0.1);
}

.rgba-brown-light,
.rgba-brown-light:after {
  background-color: rgba(121, 85, 72, 0.3);
}

.rgba-brown-strong,
.rgba-brown-strong:after {
  background-color: rgba(121, 85, 72, 0.7);
}

.rgba-blue-grey-slight,
.rgba-blue-grey-slight:after {
  background-color: rgba(96, 125, 139, 0.1);
}

.rgba-blue-grey-light,
.rgba-blue-grey-light:after {
  background-color: rgba(96, 125, 139, 0.3);
}

.rgba-blue-grey-strong,
.rgba-blue-grey-strong:after {
  background-color: rgba(96, 125, 139, 0.7);
}

.rgba-grey-slight,
.rgba-grey-slight:after {
  background-color: rgba(158, 158, 158, 0.1);
}

.rgba-grey-light,
.rgba-grey-light:after {
  background-color: rgba(158, 158, 158, 0.3);
}

.rgba-grey-strong,
.rgba-grey-strong:after {
  background-color: rgba(158, 158, 158, 0.7);
}

.rgba-black-slight,
.rgba-black-slight:after {
  background-color: rgba(0, 0, 0, 0.1);
}

.rgba-black-light,
.rgba-black-light:after {
  background-color: rgba(0, 0, 0, 0.3);
}

.rgba-black-strong,
.rgba-black-strong:after {
  background-color: rgba(0, 0, 0, 0.7);
}

.rgba-black-gradient {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #ffffff), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to top, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
}

.rgba-black-gradient {
  background: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 28%, rgba(0, 0, 0, 0.58) 75%, rgba(0, 0, 0, 0.58) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 28%, rgba(0, 0, 0, 0.58) 75%, rgba(0, 0, 0, 0.58) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 28%, rgba(0, 0, 0, 0.58) 75%, rgba(0, 0, 0, 0.58) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 28%, rgba(0, 0, 0, 0.58) 75%, rgba(0, 0, 0, 0.58) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 28%, rgba(0, 0, 0, 0.58) 75%, rgba(0, 0, 0, 0.58) 100%);

}

.rgba-white-slight,
.rgba-white-slight:after {
  background-color: rgba(255, 255, 255, 0.1);
}

.rgba-white-light,
.rgba-white-light:after {
  background-color: rgba(255, 255, 255, 0.3);
}

.rgba-white-strong,
.rgba-white-strong:after {
  background-color: rgba(255, 255, 255, 0.7);
}
